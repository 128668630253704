<template>
	<div class="account-box">
		<div class="about-banner"></div>
		<div class="section-account section">
			<div class="title-box">
				<div class="title">应收账款</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>	
			<div class="stock-msg-box">
				<div class="container">
					<img class="left-img" src="../assets/g1.png"/>
					<div class="right-text">
						<div>应收账款质押融资是指企业将赊销形成的未到期应收账款</div>
						<div>在满足一定条件的情况下以协议的形式有条件质押给银行或金融机构</div>
						<div>由后者对企业提供贸易融资的业务</div>
					</div>
				</div>
			</div>
			<img class="msg-img" src="../assets/f1.png"/>
		</div>
		
		<div class="product-box section">
			<div class="title-box">
				<div class="title">产品特点</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<div class="product-content">
				<div class="product-item">
					<img src="../assets/c1.png"/>
					<div class="main-title">授信集中</div>
					<div class="sub-title">通过对核心企业集中<br />授信供应链上游企业</div>
				</div>
				<div class="product-item">
					<img src="../assets/c2.png"/>
					<div class="main-title">交易真实</div>
					<div class="sub-title">通过平台对供应链交易真实性进行监督、整合</div>					
				</div>
				<div class="product-item">
					<img src="../assets/c3.png"/>
					<div class="main-title">风险管控</div>
					<div class="sub-title">根据金融机构要求规范融资申请信息</div>					
				</div>
			</div>
		</div>		
		
		<div class="step-box section">
			<div class="title-box">
				<div class="title">服务流程</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>	
			<img class="img-step" @click="goApply" src="../assets/step2.png"/>
		</div>	
		
		<div class="apply-box section">
			<div class="title-box">
				<div class="title">申请案例</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>			
			<div class="form">
				<img class="form-img" src="../assets/form2.png"/>
			</div>
		</div>
		
		<div class="info-box section">
			<div class="title-box">
				<div class="title">整合信息</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>			
			
			<img class="data-img" src="../assets/data-1.png"/>
			
			
			
		</div>
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			
		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_PC"});
		}
	},
	mounted(){
		
	}
}
</script>

<style lang="scss" scoped>
.account-box{
	.about-banner{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/yszkbanner.jpg);
		background-position: center;		
	}
	.section{
		.title-box{
			position: relative;
			height: 136px;
			box-sizing: border-box;
			border-bottom: solid 2px #ccc;
			padding-top: 72px;
			.title{
				font-size: 30px;
				color: #666666;
				text-align: center;
			}
			.line-box{
				position: absolute;
				left: 0px;
				bottom: -4px;
				right: 0px;
				margin: auto;
				z-index: 1;
				height: 6px;
				width: 198px;
				.left-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #2e8bb6;
				}
				.right-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #80ab35;				
				}
			}			
		}		
	}
	.section-account{
		.stock-msg-box{
			margin-top: 82px;
			width: 100%;
			background-color: #f6f6f6;
			.container{
				width: 1200px;
				height: 230px;
				margin-left: auto;
				margin-right: auto;
				.left-img{
					height: inherit;
					width: 472px;
					float: left;
				}
				.right-text{
					height: inherit;
					width: 728px;
					float: left;
					padding-top: 80px;
					padding-left: 126px;
					box-sizing: border-box;
					div{
						font-size: 18px;
						color: #666;
						line-height: 24px;
					}
				}
			}

		}
		.msg-img{
			width: 1198px;
			height: auto;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}				
	}	
	.product-box{
		height: 540px;
		background-image: url(../assets/1.jpg);
		background-position: center;
		background-size: cover;
		.title-box{
			border-bottom: solid 2px #fff;
			.title{
				color: #fff;
			}
			.line-box{
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;				
				}
			}			
		}
		.product-content{
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 404px;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
			.product-item{
				height: 240px;
				width: 240px;
				box-sizing: border-box;
				background-color: #fff;
				overflow:hidden;
				img{
					width: 241px;
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
				.main-title{
					line-height: 18px;
					font-size: 18px;
					text-align: center;
					color: #333;
				}
				.sub-title{
					width: 150px;
					margin-left: auto;
					margin-right: auto;
					margin-top: 14px;
					font-size: 14px;
					text-align: center;
					color: #666;
				}
			}
		}		
	}
	.step-box{
		width: 100%;
		background-size: cover;
		background-image: url(../assets/stepbg.jpg);
		background-position: center;
		.img-step{
			cursor: pointer;
			width: 1200px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		
	}
	.apply-box{
		.form{
			height: 1010px;
			background-image: url(../assets/bg-2.png);
			background-position: center;
			.form-img{
				width: 1200px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}	
	.info-box{
		background-color: #2e8bb6;
		.title-box{
			border-bottom: solid 2px #fff;
			.title{
				color: #fff;
			}
			.line-box{
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;				
				}
			}			
		}
		.data-img{
			display: block;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}		
	}		
}
</style>