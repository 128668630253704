<template>
	<div class="apply-box">
		<div class="banner">
			<img src="../assets/apply_m.png"/>
		</div>
		<div class="form">
			<div class="form-title">填写申请信息</div>

			<div class="input-line">
				<div class="input-title"><span class="must">*</span>姓名:</div>
				<div class="input-box">
					<input type="text" class="input" v-model="userName" maxlength="20"/>
				</div>
			</div>
			<div class="prompt">{{userNamePrompt}}</div>

			<div class="input-line">
				<div class="input-title"><span class="must">*</span>联系电话:</div>
				<div class="input-box">
					<input type="tel" class="input" v-model="userPhone" maxlength="11"/>
				</div>
			</div>
			<div class="prompt">{{userPhonePrompt}}</div>

			<div class="input-line">
				<div class="input-title">邮箱:</div>
				<div class="input-box">
					<input type="text" class="input" v-model="userEmail"/>
				</div>
			</div>
			<div class="prompt"></div>

			<div class="input-line">
				<div class="input-title">企业/个人:</div>
				<div class="input-box">
					<van-radio-group v-model="userType" class="radio-box">
						<van-radio name="0" class="radio" checked-color="#1e5b8e"><span :class="{'selected-text':userType==0}">企业</span></van-radio>
						<van-radio name="1" class="radio" checked-color="#1e5b8e"><span :class="{'selected-text':userType==1}">个人</span></van-radio>
					</van-radio-group>
				</div>
			</div>
			<div class="prompt"></div>

			<div class="input-line" v-if="userType==0">
				<div class="input-title">公司名称:</div>
				<div class="input-box">
					<input type="text" class="input" v-model="companyName" />
				</div>
			</div>
			<div class="prompt" v-if="userType==0"></div>

			<div class="input-line">
				<div class="input-title">融资模式:</div>
				<div class="input-box">
					<van-radio-group v-model="applyFor" class="radio-box">
						<van-radio name="0" class="radio-type" checked-color="#1e5b8e"><span :class="{'selected-text':applyFor==0}">存货质押</span></van-radio>
						<van-radio name="1" class="radio-type" checked-color="#1e5b8e"><span :class="{'selected-text':applyFor==1}">应收账款</span></van-radio>
					</van-radio-group>
				</div>
			</div>
			<div class="prompt"></div>

			<div class="input-line" v-if="applyFor==0">
				<div class="input-title"><span class="must">*</span>质押品类:</div>
				<div class="input-box">
					<input type="text" class="input" v-model="pledgeGood" key='pledgeGood'/>
				</div>
			</div>


			<div class="input-line" v-if="applyFor==1">
				<div class="input-title"><span class="must">*</span>所属行业:</div>
				<div class="input-box">
					<van-field
					  class="sel-input"
					  readonly
					  clickable
					  :value="serviceText"
					  placeholder="请选择所属行业"
					  @click="showPicker = true"
					/>

<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="columns"
    @cancel="showPicker = false"
    @confirm="onConfirm"
  />
</van-popup>



				</div>
			</div>
			<div class="prompt">{{pledgeGoodPrompt}}{{serviceTypePrompt}}</div>



			<div class="input-line">
				<div class="input-title">融资需求:</div>
				<div class="input-box input-box-end">
					<input type="text" class="input input-end" v-model="needMoney" maxlength="10"/>
					<div class="end">万元</div>
				</div>
			</div>
			<div class="prompt">{{needMoneyPrompt}}</div>

			<div class="mark-box">
				<div class="mark-title">备注:</div>
				<div class="mark-input">
					<textarea class="textarea" maxlength="50" v-model="userRemark"></textarea>

				</div>

			</div>

			<div class="submit" @click="submit">提交申请</div>




		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法
export default {
  data() {
    return {
      userType: '0',
      applyFor:'0',

      showPicker: false,
      submitFlag:true,
      userRemark:"",
      userName:"",
      userNamePrompt:"",
      userPhone:"",
      userPhonePrompt:"",
      userEmail:"",
      companyName:"",
      pledgeGood:"",
      pledgeGoodPrompt:"",
      serviceText:"",
      serviceType: '',
      serviceTypePrompt:"",
      needMoney:"",
      needMoneyPrompt:"",
	columns: [
		{
			"text": "农、林、牧、渔业",
			"val": 1,
		},
		{
			"text": "采矿业",
			"val": 2,
		},
		{
			"text": "制造业",
			"val": 3,
		},
		{
			"text": "电力、热力、燃气及水的生产和供应业",
			"val": 4,
		},
		{
			"text": "环境和公共设施管理业",
			"val": 5,
		},
		{
			"text": "建筑业",
			"val": 6,
		},
		{
			"text": "交通运输、仓储业和邮政业",
			"val": 7,
		},
		{
			"text": "信息传输、计算机服务和软件业",
			"val": 8,
		},
		{
			"text": "批发和零售业",
			"val": 9,
		},
		{
			"text": "住宿、餐饮业",
			"val": 10,
		},
		{
			"text": "金融、保险业",
			"val": 11,
		},
		{
			"text": "房地产业",
			"val": 12,
		},
		{
			"text": "租赁和商务服务业",
			"val": 13,
		},
		{
			"text": "科学研究、技术服务和地质勘查业",
			"val": 14,
		},
		{
			"text": "水利、环境和公共设施管理业",
			"val": 15,
		},
		{
			"text": "居民服务和其他服务业",
			"val": 16,
		},
		{
			"text": "教育",
			"val": 17,
		},
		{
			"text": "卫生、社会保障和社会服务业",
			"val": 18,
		},
		{
			"text": "文化、体育、娱乐业",
			"val": 19,
		},
		{
			"text": "综合（含投资类、主业不明显）",
			"val": 20,
		},
		{
			"text": "其他",
			"val": 21,
		},
	]

    }
  },
  methods: {
    onConfirm(value) {
      this.serviceText = value.text;
      this.serviceType = value.val;
      this.showPicker = false;
    },
    submit(){
    	if(this.submitFlag){
			if(this.userName.length===0){
				this.userNamePrompt="! 申请人姓名不可为空";
				this.$toast('请填写相关必填项');
				return;
			}else{
				this.userNamePrompt="";
			}

			if(this.userPhone.length===0){
				this.userPhonePrompt="! 联系电话不可为空";
				this.$toast('请填写相关必填项');
				return;
			}else{
				this.userPhonePrompt="";
			}
		    let isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;//手机号码
		    let isMob= /^0?1[3|4|5|8][0-9]\d{8}$/;// 座机格式  区号之后用'-'隔开
		    if(isMob.test(this.userPhone)||isPhone.test(this.userPhone)){

		    }
		    else{
				this.userPhonePrompt="! 联系电话格式不正确";
				this.$toast('联系电话格式不正确');
		        return;
		    }




			if(this.applyFor==0){
				if(this.pledgeGood.length===0){
					this.pledgeGoodPrompt="! 质押品类不可为空";
					this.$toast('请填写相关必填项');
					return;
				}else{
					this.pledgeGoodPrompt="";
				}
			}else{
				if(this.serviceType===""){
					this.serviceTypePrompt="! 请选择服务类型";
					this.$toast('请填写相关必填项');
					return
				}else{
					this.serviceTypePrompt="";
				}
			}

			if(this.needMoney!==""){
				let reg = /^[1-9]\d*$/;
				if(reg.test(this.needMoney)){
					this.needMoneyPrompt="";
				}else{
					this.$toast('融资需求格式不正确');
					this.needMoneyPrompt="! 请输入正整数";
					return;
				}
			}else{
				this.needMoneyPrompt="";
			}



			let sendData={};

			let userName=this.userName;
			let userPhone=this.userPhone;
			let userEmail=this.userEmail;
			let userType=this.userType;
			let applyFor=this.applyFor;
			let needMoney=this.needMoney;
			let userRemark=this.userRemark;
			sendData={userName,userPhone,userEmail,userType,applyFor,needMoney,userRemark};

			if(userType==0){
				sendData.companyName=this.companyName;
			}else{

			}
			if(applyFor==0){
				sendData.pledgeGood=this.pledgeGood;
			}else{
				sendData.serviceType=this.serviceType;
			}

			api.post('hyr/user/customApply/saveCustomApply', sendData).then((res) => {
				this.submitFlag=true;
				if(res.data.status){
					this.$toast('提交成功');
					setTimeout(()=>{
						this.successFlag=false;
						this.$router.push({name:"Home_M"});
					},350)
				}else{
					this.$toast('提交失败');
					setTimeout(()=>{
						this.successFlag=false;
					},350)

				}


			}).catch((res) => {this.submitFlag=true;})





    	}
    }
  },
  mounted(){
  	//this.$toast('请填写必填项');
    this.WXConfig.wxShowMenu();
  }
};
</script>
<!--<style src="../../public/css/index.css"></style>-->
<style lang="scss" scoped>
@import url("../../public/css/index.css");
.apply-box{
	.banner{
		width: 100%;
		height: 197.5px;
		img{
			width: inherit;
			width: inherit;
		}
	}
	.form{
		padding-left: 17.5px;
		padding-right: 17.5px;
		.form-title{
			padding-top: 32px;
			padding-bottom: 32px;
			font-size: 16px;
			color: #333;
		}
		.input-line{
			height: 46px;
			overfloat:hidden;
			.input-title{
				width: 66px;
				height: 46px;
				float: left;
				text-align: right;
				color: #666;
				line-height: 46px;
				font-size: 14px;
				.must{
					color: #DD5044;
				}
			}
			.input-box{
				float: left;
				height: 46px;
				width: 274px;
				box-sizing: border-box;
				padding-left: 17px;
				.input{
					display: block;
					height: 46px;
					width: 257px;
					box-sizing: border-box;
					border: solid 1px #cccccc;
					outline: none;
					font-size: 14px;
					padding-left: 16px;
					padding-right: 16px;
					color: #333;
					&:focus{
						border-color: #2E8BB6;
					}
				}
				.input-end{
					padding-right: 60px;
				}
				.radio-box{
					display: flex;
					height: 100%;
					width: 100%;
					font-size: 14px;
					.radio{
						margin-right: 45px;
					}
					.radio-type{
						margin-right: 16px;
					}
					/deep/ .van-radio__label{
						color: #999;
					}
					.selected-text{
						color: #1e5b8e;
					}
				}
				.sel-input{
					height: 100%;
					width: 100%;
					box-sizing: border-box;
					border: solid 1px #ccc;
				}
			}
			.input-box-end{
				position: relative;
				.end{
					width: 60px;
					line-height: 46px;
					font-size: 14px;
					color: #666;
					text-align: center;
					position: absolute;
					right: 0px;
					top: 0px;
				}
			}
		}
		.prompt{
			height: 20px;
			width: 100%;
			line-height: 20px;
			box-sizing: border-box;
			font-size: 12px;
			color: #DD5044;
			padding-left: 85px;
		}

		.mark-box{
			overflow: hidden;
			.mark-title{
				width: 66px;
				height: 46px;
				float: left;
				text-align: right;
				color: #666;
				line-height: 46px;
				font-size: 14px;
			}
			.mark-input{
				height: 90px;
				width: 274px;
				float: left;
				box-sizing: border-box;
				padding-left: 17px;
				.textarea{
					width: 257px;
					height: 90px;
					border: solid 1px #ccc;
					box-sizing: border-box;
					padding: 16px;
					resize: none;
					font-size: 14px;
					color: #333;
					&:focus{
						border-color: #1E5B8E;
					}
				}
			}
		}
		.submit{
			height: 40px;
			width: 150px;
			background-color: #1e5b8e;
			line-height: 40px;
			color: #fff;
			text-align: center;
			font-size: 16px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 50px;
			margin-bottom: 50px;
		}

	}
}


.input-line{
	/deep/ .van-picker__cancel{
		color: #666 !important;
	}
	/deep/ .van-picker__confirm{
		color: #1e5b8e !important;
	}
}


</style>
