<template>
  <div class="primary-box">
    <div class="banner-box">
      <div class="banner-list">
        <div class="banner-item" :class="{'banner-active':currentIndex===0}"></div>
       <!-- <div class="banner-item" :class="{'banner-active':currentIndex===1}"></div>
        <div class="banner-item" :class="{'banner-active':currentIndex===2}"></div>
        <div class="banner-item" :class="{'banner-active':currentIndex===3}"></div>
        <div class="banner-item" :class="{'banner-active':currentIndex===4}"></div> -->
      </div>
     <!-- <div class="index-list">
        <div class="index-item" :class="{'index-active':currentIndex===0}" @mouseenter="enter(0)"
             @mouseleave="leave()"></div>
        <div class="index-item" :class="{'index-active':currentIndex===1}" @mouseenter="enter(1)"
             @mouseleave="leave()"></div>
        <div class="index-item" :class="{'index-active':currentIndex===2}" @mouseenter="enter(2)"
             @mouseleave="leave()"></div>
        <div class="index-item" :class="{'index-active':currentIndex===3}" @mouseenter="enter(3)"
             @mouseleave="leave()"></div>
        <div class="index-item" :class="{'index-active':currentIndex===4}" @mouseenter="enter(4)"
             @mouseleave="leave()"></div>
      </div> -->
    </div>
    <div class="business-box section">
      <div class="title-box">
        <div class="title">公司介绍</div>
        <div class="line-box">
          <div class="left-line"></div>
          <div class="right-line"></div>
        </div>
      </div>


      <div class="business-content">
      
        <div class="nick">
        
          <div class="business-item">
           <!-- <div class="item-title">
             <div class="left-title">
                <span class="iconfont icon-bill"></span>
                <span class="text">简介</span>
              </div>
            </div> -->
            <div class="item-message">
              <div class="msg">
				  沈阳盛世汇智网络科技有限公司成立于2016年4月，是一家以软件研发与技术服务为核心，</br>
				  
				  以设计咨询、智能工程、系统集成、大数据分析及商业运营为能力的创新型科技公司，</br>
				  
				  公司专注于体育科技、系统集成、综合运营三大板块，以软件开发为核心，融合商业运营经验在不同的科技及平台领域进行跨界创新实践并最终实现其运用价值。
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



   <!-- <div class="news-box section">
     
      <div class="news-content">
        <div class="news-list">
          <div class="placeholder"></div>
          <div class="news-item" v-for="(item,index) of newsList" :key="item.newsId" @click="goNewsDetail(item.newsId)">
            <div class="date-box">
              <div class="month">{{ item.createTime | getMonth }}</div>
              <div class="year">{{ item.createTime | getYear }}</div>
            </div>
            <div class="msg-box">
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-msg">{{ item.newsMessageText }}</div>
            </div>
          </div>
        </div>
        <div class="more-btn" @click="goNewsList">查看更多</div>
      </div>
    </div>
 -->

  </div>
</template>

<script>
import {api, axios} from "../tools/service.js" //封装axios方法
export default {
  data() {
    return {
      currentIndex: 0,
      timer: null,
      sliderList: [
        {"src": require("../assets/new.png")},
        {"src": require("../assets/51.png")}
      ],
      index: 0,
      leftFlag: false,
      rightFlag: false,
      tempFlag: false,
      banFlag: true,
      newsList: [],
    }
  },
  filters: {
    getMonth(val) {
      return val.substr(val.length - 5).replace('-', '/');
    },
    getYear(val) {
      return val.substring(0, 4)
    }
  },
  methods: {
    goAccount() {
      this.$router.push({name: "Account_PC"});
    },
    goStock() {
      this.$router.push({name: "Stock_PC"});
    },
    enter(index) {
      this.currentIndex = index;
      clearInterval(this.timer);
    },
    leave() {
      this.autoPlay();
    },
    autoPlay() {
      this.timer = setInterval(() => {
        if (this.currentIndex < 3) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      }, 3500)
    },
    removeItemLeft() {
      let objList = document.getElementById("img-list");
      let objFirstItem = document.getElementsByClassName("img-item")[0];
      let tempItem = objList.removeChild(objFirstItem);
      objList.appendChild(tempItem);
    },
    removeItemRight() {
      let objList = document.getElementById("img-list");
      let objLastItem = document.getElementsByClassName("img-item")[this.sliderList.length - 1];
      let tempItem = objList.removeChild(objLastItem);
      objList.insertBefore(tempItem, objList.firstChild);

    },


    previous() {
      if (this.banFlag) {
        this.banFlag = false;
        this.removeItemRight();
        if (this.index > 0) {
          this.index--;
        } else {
          this.index = this.sliderList.length - 1;
        }
        this.tempFlag = true;
        setTimeout(() => {
          this.rightFlag = true;
          this.tempFlag = false;
          setTimeout(() => {
            this.rightFlag = false;
            this.banFlag = true;
          }, 700)
        }, 50)
      }
    },
    next() {
      if (this.banFlag) {
        this.banFlag = false;
        this.leftFlag = true;
        if (this.index < this.sliderList.length - 1) {
          this.index++;
        } else {
          this.index = 0;
        }

        setTimeout(() => {
          this.leftFlag = false;
          this.removeItemLeft();
          this.banFlag = true;
        }, 750)
      }


    },
    apply() {
      this.$router.push({name: "Apply_PC"});
    },
    getNewsList() {
      api.post('hyr/user/news/getNewsMessageList', {
        pageNo: 1, pageSize: 4
      }).then((res) => {
        if (res.data.status) {
          this.newsList = res.data.resultBody.message;
        } else {

        }

      }).catch((res) => {
      })
    },
    goNewsList() {
      this.$router.push({name: "NewsList_PC", query: {page: 1}});
    },
    goNewsDetail(newsId) {
      this.$router.push({name: "NewsDetail_PC", query: {newsId}});
    },
  },
  mounted() {
    this.getNewsList();
//		this.autoPlay();
  }
}
</script>

<style lang="scss" scoped>
.video1 {
  padding-right: 60px;
}

.primary-box {
  width: 100%;
  height: auto;

  .banner-box {
    position: relative;
    width: 100%;
    height: 680px;

    .banner-list {
      position: relative;
      width: inherit;
      height: inherit;

      .banner-item {
        position: absolute;
        width: inherit;
        height: inherit;
        z-index: 0;
        opacity: 0;
        transition: all 0.35s;
        background-size: cover;
        background-position: center;

        &:nth-child(1) {
          background-image: url(../assets/new_bg.png);
        }

        &:nth-child(2) {
          background-image: url(../assets/banner02.jpg);
        }

        &:nth-child(3) {
          background-image: url(../assets/banner03.jpg);
        }

        &:nth-child(4) {
          background-image: url(../assets/banner04.jpg);
        }

        &:nth-child(5) {
          background-image: url(../assets/banner05.jpg);
        }
      }

      .banner-active {
        opacity: 1;
        z-index: 1;
      }
    }

    .index-list {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 10px;
      left: 0px;
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;

      .index-item {
        float: left;
        width: 100px;
        height: 8px;
        background-color: #fff;
        cursor: pointer;
        margin-left: 3px;
        margin-right: 3px;
        transition: all 0.35s;

        &:hover {
          background-color: #2e8bb6;
        }
      }

      .index-active {
        background-color: #2e8bb6;
      }
    }

  }

  .section {
    .title-box {
      position: relative;
      height: 136px;
      box-sizing: border-box;
      border-bottom: solid 2px #ccc;
      padding-top: 72px;

      .title {
        font-size: 30px;
        color: #666666;
        text-align: center;
      }

      .line-box {
        position: absolute;
        left: 0px;
        bottom: -4px;
        right: 0px;
        margin: auto;
        z-index: 1;
        height: 6px;
        width: 198px;

        .left-line {
          float: left;
          width: 94px;
          height: 6px;
          background-color: #2e8bb6;
        }

        .right-line {
          float: left;
          width: 94px;
          height: 6px;
          background-color: #80ab35;
        }
      }
    }
  }

  .business-box {
    height: 636px;
    background-color: #fff;

    .business-content {
      width: 1200px;
      margin: 0 auto;
      height: 500px;
      box-sizing: border-box;
      background-color: #fff;
      padding-top: 80px;
      padding-bottom: 80px;
      display: flex;

      .business-item {
        height: 200px;

        .item-title {
          display: flex;
          height: 74px;
          box-sizing: border-box;
          border-bottom: solid 1px #ccc;

          .left-title {
            position: relative;
            height: 74px;
            width: 160px; /*  修改 border-bottom: 带颜色的宽度 */
            line-height: 72px;
            box-sizing: border-box;
            border-bottom: solid 2px #2e8bb6;

            .iconfont {
              font-size: 50px;
              color: #333;
            }

            .text {
              position: absolute;
              top: 12px;
              font-family: Microsoft YaHei;
              margin-left: 16px;
              font-size: 18px;
              color: #333;
              margin-top: -10px;
            }
          }
        }

        .item-message {
          height: 162px;
          box-sizing: border-box;
          margin-top: 10px;

          .msg {
            font-size: 24px;
            color: #666;
            line-height: 50px;
          }

          .button {
            border-bottom: 1.5px solid #2e8bb6;
            box-sizing: border-box;
            text-align: center;
            color: #2e8bb6;
            user-select: none;
            cursor: pointer;
            transition: all 0.35s;

            &:hover {
              color: #fff;
              background-color: #2e8bb6;
            }
          }

        }
      }
    }

  }

  .product-box {
    height: 540px;
    background-image: url(../assets/1.jpg);
    background-position: center;
    background-size: cover;

    .title-box {
      border-bottom: solid 2px #fff;

      .title {
        color: #fff;
      }

      .line-box {
        .left-line {
          background-color: #fff;
        }

        .right-line {
          background-color: #fff;
        }
      }
    }

    .product-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 404px;
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .product-item {
        height: 240px;
        width: 240px;
        box-sizing: border-box;
        background-color: #fff;

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        .main-title {
          line-height: 18px;
          font-size: 18px;
          text-align: center;
          color: #333;
        }

        .sub-title {
          margin-top: 14px;
          font-size: 14px;
          text-align: center;
          color: #666;
        }
      }
    }
  }

  .technology-box {
    height: 1130px;

    .technology-content {
      position: relative;
      width: 1200px;
      height: 718px;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;

      .technology-wraper {
        margin-top: 80px;
        width: 1103px;
        height: 718px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;

        .img-list {
          height: 718px;

          .img-item {
            height: 718px;
            width: 1103px;
            float: left;

            img {
              height: inherit;
              width: inherit;
            }
          }
        }

        .active-temp {
          margin-left: -1103px;
        }

        .active-left {
          margin-left: -1103px;
          transition: all 0.75s;
        }

        .active-right {
          margin-left: 0px;
          transition: all 0.75s;
        }
      }

      .arrow-box {
        position: absolute;
        top: 300px;
        left: -62px;
        height: 118px;
        width: 1320px;

        .iconfont {
          cursor: pointer;
          font-size: 58px;
          line-height: 118px;
          transition: all 0.35s;
          color: #ccc;

          &:hover {
            color: #333;
          }
        }

        .icon-right {
          float: right;
        }

        .icon-left-copy {
          float: left;
        }
      }

      .apply-btn {
        transition: all 0.35s;
        background-color: #1e5b8e;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;
        width: 320px;
        height: 62px;
        line-height: 62px;
        text-align: center;
        color: #fff;
        font-size: 30px;
        user-select: none;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .cooperation-box {
    height: 524px;

    .cooperation-content {
      height: 388px;
      width: 1200px;
      box-sizing: border-box;
      padding-top: 82px;
      margin-left: auto;
      margin-right: auto;

      .company-list {
        height: 222px;
        display: flex;
        justify-content: space-between;

        .company-item {
          overflow: hidden;
          cursor: pointer;
          background-color: #fff;
          // height: 222px;
          // width: 330px;
          box-sizing: border-box;
          border: solid 1px #d5d5d5;

          img {
            transition: all 0.35s;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 330px;
            height: 222px;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  .news-box {
    height: 660px;
    background-color: #fff;

    .news-content {
      position: relative;
      height: 524px;
      width: 1200px;
      margin-left: auto;
      margin-right: auto;

      .news-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .placeholder{
          height: 42px;
          width: 100%;
        }
        .news-item {
          cursor: pointer;
          padding-top: 36px;
          height: 150px;
          width: 544px;
          box-sizing: border-box;
          border-bottom: dashed 1px #e5e5e5;

          &:hover {
            .msg-box {
              .news-msg {
                color: #2E8BB6;
              }
            }
          }

          .date-box {
            box-sizing: border-box;
            padding-top: 4px;
            width: 106px;
            height: 100px;
            float: left;

            .month {
              font-size: 20px;
              color: #333;
              line-height: 20px;
            }

            .year {
              font-size: 14px;
              line-height: 14px;
              color: #999;
            }
          }

          .msg-box {
            float: left;
            width: 438px;

            .news-title {
              font-size: 18px;
              color: #333;
              margin-bottom: 5px;
            }

            .news-msg {
              color: #666;
              font-size: 14px;
              line-height: 28px;
              height: 56px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .more-btn {
        position: absolute;
        bottom: 80px;
        left: 0px;
        right: 0px;
        margin: auto;
        width: 240px;
        height: 60px;
        background-color: #1e5b8e;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        user-select: none;
        transition: all 0.35s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

</style>
