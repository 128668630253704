<template>
	<div class="news-detail-box">
		<div class="detail-banner"></div>
		<div class="title-box">
			<div class="title">{{newsTitle}}</div>
			<div class="date">{{createTime}}</div>
		</div>		
		<div class="detail-content">
			<div v-html="newsMessage"></div>
		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法		
export default {
	data(){
		return {
			newsTitle:"",
			createTime:"",
			newsMessage:"",
		}
	},
	methods:{
		getNewsDetail(newsId){
			api.post('hyr/user/news/findNewsMessageById', {
				newsId
			}).then((res) => {
				if(res.data.status){
					this.newsTitle=res.data.resultBody.newsTitle;
					this.createTime=res.data.resultBody.createTime;
					this.newsMessage=res.data.resultBody.newsMessage;

				}else{

				}
				
			}).catch((res) => {})			
		}
	},
	mounted(){
		let newsId=this.$route.query.newsId;
		this.getNewsDetail(newsId);
	}
}
</script>

<style lang="scss" scoped>
.news-detail-box{
	.detail-banner{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/newsbanner.jpg);
		background-position: center;
	}
	.title-box{
		height: 182px;
		box-sizing: border-box;
		padding-top: 80px;
		border-bottom: solid 1px #b3b3b3;
		.title{
			font-size: 30px;
			color: #333;
			text-align: center;
		}
		.date{
			margin-top: 8px;
			font-size: 18px;
			color: #666;
			text-align: center;

		}
	}	
	.detail-content{
		font-size: 16px;
		padding-top: 34px;
		padding-bottom: 60px;
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		/deep/ img {
			max-width: 1200px !important;
			height: auto;
			
		}
		/deep/ ol{
			padding-left: 20px;
		}	
		/deep/ ul{
			padding-left: 20px;
		}		
	}	
}

</style>