<template>
	<div class="stock-box">
		<div class="banner">
			<img src="../assets/stock-m.png"/>
		</div>
		<div class="about">
			<div class="title-box">
				<div class="text-box">
					存货质押
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="img-title" src="../assets/bm01.png"/>
			<div class="box-msg">存货质押是指客户向资金提供方出质，同时将质物转交给具有合法保管存货资格的物流企业进行保管，以获得资金的业务活动</div>
			<img class="form-img" src="../assets/stock-m2.png"/>
		</div>

		<div class="product-box">
			<div class="title-box">
				<div class="text-box">
					产品特点
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<div class="img-box">
				<img class="product-img" src="../assets/pm1.png"/>
				<img class="product-img" src="../assets/pm2.png"/>
				<img class="product-img" src="../assets/pm3.png"/>
			</div>

		</div>

		<div class="example-box">
			<div class="title-box">
				<div class="text-box">
					仓单案例
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="example-img" src="../assets/stock-m3.png"/>
		</div>

		<div class="process-box">
			<div class="title-box">
				<div class="text-box">
					服务流程
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img @click="goApply" class="process-img" src="../assets/stock-m-process.png"/>
		</div>

		<div class="data-box">
			<div class="title-box">
				<div class="text-box">
					数据统计
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="data-img" src="../assets/stock-m4.png"/>
		</div>
		<v-footer></v-footer>


	</div>
</template>

<script>
import VFooter from "./components/Footer.vue"
export default {
	components:{
		VFooter,
	},
	data(){
		return {

		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_M"});
		}
	},
  mounted(){
    this.WXConfig.wxShowMenu();
  }
}




</script>

<style lang="scss" scoped>
.stock-box{
	.title-box{
		height: 54px;
		width: 100%;
		border-bottom: solid 1px #cccccc;
		.text-box{
			position: relative;
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			line-height: 54px;
			font-size: 20px;
			color: #666666;
			.left-line{
				position: absolute;
				bottom: -2px;
				left: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #2e8bb6;
			}
			.right-line{
				position: absolute;
				bottom: -2px;
				right: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #80ab35;
			}
		}
	}
	.banner{
		width: 100%;
		height: 180px;
		img{
			width: 100%;
			height: inherit;
		}
	}
	.about{
		padding-top: 19px;
		padding-bottom: 22px;
		.img-title{
			display: block;
			margin-top: 42px;
			width: 340px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		.box-msg{
			margin-top: 28px;
			width: 340px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			line-height: 20px;
			color: #333;
		}
		.form-img{
			display: block;
			width: 340px;
			height: auto;
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.product-box{
		height: 209px;
		background-image: url(../assets/bg-m1.png);
		background-position: center;
		.title-box{
			border-bottom: solid 1px #fff;
			.text-box{
				color: #fff;
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;
				}
			}

		}
		.img-box{
			display: flex;
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			padding-top: 26px;
			justify-content: space-between;
			.product-img{
				width: 100px;
				height: 107px;
			}
		}

	}
	.example-box{
		padding-top: 19px;
		.example-img{
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-top: 37px;
			width: 100%;
			height: auto;
		}
	}
	.process-box{
		width: 100%;
		height: 223px;
		background-size: cover;
		background-position: center;
		background-image: url(../assets/stock-m-bg.jpg);
		.process-img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.data-box{
		padding-bottom: 30px;
		.data-img{
			margin-top: 32px;
			display: block;
			width: 100%;
			height: auto;
		}
	}
}





</style>
