<template>
	<div class="account-box">
		<div class="banner">
			<img src="../assets/account-m.png"/>
		</div>
		<div class="about">
			<div class="title-box">
				<div class="text-box">
					应收账款
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="img-title" src="../assets/bm02.png"/>
			<div class="box-msg">应收账款质押是指企业将赊销形成的未到期应收账款，以协议的形式有条件质押给银行或金融机构，由后者对企业提供贸易融资的业务</div>
			<img class="form-img" src="../assets/account-m2.png"/>
		</div>

		<div class="product-box">
			<div class="title-box">
				<div class="text-box">
					产品特点
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<div class="img-box">
				<img class="product-img" src="../assets/account-m-l1.png"/>
				<img class="product-img" src="../assets/account-m-l2.png"/>
				<img class="product-img" src="../assets/account-m-l3.png"/>
			</div>

		</div>

		<div class="process-box">
			<div class="title-box">
				<div class="text-box">
					服务流程
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img @click="goApply" class="process-img" src="../assets/account-m3.png"/>
		</div>

		<div class="example-box">
			<div class="title-box">
				<div class="text-box">
					申请案例
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="example-img" src="../assets/account-m4.png"/>
		</div>


		<div class="info-box">
			<div class="title-box">
				<div class="text-box">
					整合信息
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="info-img" src="../assets/account-m5.png"/>
		</div>
		<v-footer></v-footer>

	</div>
</template>

<script>
import VFooter from "./components/Footer.vue"
export default {
	components:{
		VFooter,
	},
	data(){
		return {

		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_M"});
		}
	},
  mounted() {
    this.WXConfig.wxShowMenu();
  }
}
</script>

<style lang="scss" scoped>
.account-box{
	.title-box{
		height: 54px;
		width: 100%;
		border-bottom: solid 1px #cccccc;
		.text-box{
			position: relative;
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			line-height: 54px;
			font-size: 20px;
			color: #666666;
			.left-line{
				position: absolute;
				bottom: -2px;
				left: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #2e8bb6;
			}
			.right-line{
				position: absolute;
				bottom: -2px;
				right: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #80ab35;
			}
		}
	}
	.banner{
		width: 100%;
		height: 180px;
		img{
			width: inherit;
			height: inherit;
		}
	}
	.about{
		padding-top: 19px;
		padding-bottom: 22px;
		.img-title{
			display: block;
			margin-top: 42px;
			width: 340px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		.box-msg{
			margin-top: 28px;
			width: 340px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			line-height: 20px;
			color: #333;
		}
		.form-img{
			display: block;
			width: 340px;
			height: auto;
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.product-box{
		height: 209px;
		background-image: url(../assets/bg-m1.png);
		background-position: center;
		.title-box{
			border-bottom: solid 1px #fff;
			.text-box{
				color: #fff;
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;
				}
			}

		}
		.img-box{
			display: flex;
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			padding-top: 26px;
			justify-content: space-between;
			.product-img{
				width: 100px;
				height: 107px;
			}
		}

	}
	.process-box{
		width: 100%;
		height: 223px;
		background-size: cover;
		background-position: center;
		background-image: url(../assets/stock-m-bg.jpg);
		.process-img{
			display: block;
			width: 100%;
			height: auto;
			/*width: 100%;*/
			/*height: 150px;*/
		}
	}
	.example-box{
		padding-bottom: 30px;
		.example-img{
			margin-top: 36px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 341px;
			height: auto;
		}
	}
	.info-box{
		height: 327px;
		background-color: #2E8BB6;
		.title-box{
			border-bottom: solid 1px #fff;
			.text-box{
				color: #fff;
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;
				}
			}

		}
		.info-img{
			display: block;
			width: 100%;
			height: auto;
		}
	}


}


</style>
