<template>
	<div class="course-box">
		<div class="banner">
			<img src="../assets/glory_m.png"/>
		</div>
		<div class="container">
			<div class="step-list">
				<div class="step-item-year">
					<div class="vertical-line"></div>
					<div class="year-pointer">
						<div class="inner"></div>
					</div>
					<div class="right-side">
						<div class="iconfont icon-calendar"></div>
						<div class="year">2017年</div>
					</div>
				</div>
				<div class="step-item-text-1">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text">入沈阳市高新技术企业培育库</div>
					</div>
				</div>
				<div class="step-item-text-1 step-item-text-2">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text">入库科技型中小企业</div>
						<div class="text">编号：201821011200000322</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-611">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text"><span>2017</span>计算机软件著作权登记证书</div>
						<img class="img-c1" src="../assets/c1_m.png"/>
						<div class="list">
							<div class="item">1、货易融供应链在线数据分析软件V1.0</div>
							<div class="item">2、货易融客户信息管理系统软件V1.0</div>
							<div class="item">3、货易融存货仓储管理系统软件V1.0</div>
							<div class="item">4、货易融存货监管仓地理信息管理软件V1.0</div>
							<div class="item">5、货易融存货射频智能识别系统软件V1.0</div>
							<div class="item">6、货易融智能仓报警系统软件V1.0</div>
						</div>
					</div>
				</div>

				<div class="step-item-year">
					<div class="vertical-line"></div>
					<div class="year-pointer">
						<div class="inner"></div>
					</div>
					<div class="right-side">
						<div class="iconfont icon-calendar"></div>
						<div class="year">2018年</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-221">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text">2018.4.28</div>
						<div class="text">沈阳高新区杰出青年人物和青年群体</div>
						<img class="img-c6" src="../assets/c6_m.png"/>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-586">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text"><span>2018</span>计算机软件著作权登记证书</div>
						<img class="img-c2" src="../assets/c2_m.png"/>
						<div class="list">
							<div class="item">1、货易融存货与不良品管理系统软件V1.0</div>
							<div class="item">2、货易融存货盘点管理系统软件V1.0</div>
							<div class="item">3、货易融进销存管理系统软件V1.0</div>
							<div class="item">4、货易融存货质押监管风险预警系统软件V1.0</div>
							<div class="item">5、货易融科技企业站软件V1.1.0</div>
						</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-231">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text">2018.7.31   高新技术企业</div>
						<img class="img-c5" src="../assets/c5_m.png"/>
					</div>
				</div>


				<div class="step-item-year">
					<div class="vertical-line"></div>
					<div class="year-pointer">
						<div class="inner"></div>
					</div>
					<div class="right-side">
						<div class="iconfont icon-calendar"></div>
						<div class="year">2019年</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-2">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text">沈阳市规模以下</div>
						<div class="text">科技型企业研发投入前300名</div>
					</div>
				</div>


				<div class="step-item-text-1 step-item-text-588">
					<div class="vertical-line"></div>
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text"><span>2019</span>计算机软件著作权登记证书</div>
						<img class="img-c3" src="../assets/c3_m.png"/>
						<div class="list">
							<div class="item">1、账易融供应链维护方融资申请管理系统V1.0</div>
							<div class="item">2、账易融供应链机构方项目管理系统V1.0</div>
							<div class="item">3、爱笑工作室智能办公知识管理系统V1.0</div>
							<div class="item">4、爱笑工作室智能办公日程管理系统V1.0</div>
							<div class="item">5、爱笑工作室智能办公用户管理系统V1.0</div>
						</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-430">
					<div class="pointer"></div>
					<div class="vertical-line"></div>
					<div class="right-side">
						<div class="text">2019年12月  获火炬统计先进单位</div>
						<img class="img-c4" src="../assets/c9_m.jpg"/>
					</div>
				</div>

				<div class="step-item-year">
					<div class="vertical-line"></div>
					<div class="year-pointer">
						<div class="inner"></div>
					</div>
					<div class="right-side">
						<div class="iconfont icon-calendar"></div>
						<div class="year">2021年</div>
					</div>
				</div>

				<div class="step-item-text-1 step-item-text-600">
					<div class="pointer"></div>
					<div class="right-side">
						<div class="text"><span>2021</span>计算机软件著作权登记证书</div>
						<img class="img-c3" src="../assets/c10_m.png"/>
						<div class="list">
							<div class="item">1、货...质物信息与环境监测设备管理系统软件V1.0</div>
							<div class="item">2、货...融项目实时监管信息集成管理软件软件V1.0</div>
							<div class="item">3、货易融电子合同在线签约系统软件V1.0</div>
							<div class="item">4、融资申请与评审系统软件V1.0</div>
							<div class="item">5、货易融客户信息管理系统软件V2.0</div>
							<div class="item">6、货易融智能仓报警系统软件V2.0</div>
							<div class="item">7、软件货易融存货仓储管理系统软件V2.0</div>
							<div class="item">8、货易融供应链在线数据分析软件V2.0</div>
							<div class="item">9、货易融存货监管仓地理信息管理V2.0</div>
						</div>
					</div>
				</div>



			</div>
		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
import VFooter from "./components/Footer.vue"
export default {
	components:{
		VFooter,
	},
	data(){
		return {

		}
	},
	methods:{

	},
	mounted(){
    this.WXConfig.wxShowMenu();
	}
}
</script>

<style lang="scss" scoped>
.course-box{
	.banner{
		width: 100%;
		height: 197.5px;
		img{
			height: inherit;
			width: inherit;
		}
	}
	.container{
		box-sizing: border-box;
		padding-top: 40px;
		padding-left: 17.5px;
		padding-right: 17.5px;
		.step-list{
			.step-item-year{
				position: relative;
				height: 75px;
				width: 340px;
				.vertical-line{
					position: absolute;
					height: inherit;
					width: 1px;
					background-color: #ccc;
					left: 12px;
					top: 0px;
				}
				.year-pointer{
					position: absolute;
					left: 2px;
					top: 0px;
					height: 18px;
					width: 18px;
					border-radius: 50%;
					border: solid 1px #2e8bb6;
					background-color: #fff;
					display: flex;
					align-items: center;
					.inner{
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background-color: #2e8bb6;
						margin-left: auto;
						margin-right: auto;
					}
				}
				.right-side{
					position: relative;
					.icon-calendar{
						position: absolute;
						left: 33px;
						top: -12px;
						font-size: 40px;
						color: #333;
					}
					.year{
						font-size: 20px;
						color: #333;
						position: absolute;
						left: 70px;
						top: -4px;
					}
				}
			}
			.step-item-text-1{
				position: relative;
				height: 55px;
				width: 340px;
				.vertical-line{
					position: absolute;
					height: inherit;
					width: 1px;
					background-color: #ccc;
					left: 12px;
					top: 0px;
				}
				.pointer{
					position: absolute;
					left: 8.5px;
					top: 0px;
					height: 9px;
					width: 9px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}
				.right-side{
					position: absolute;
					top: -7px;
					left: 40px;
					.list{
						width: 315px;
						padding-top: 14px;
						.item{
							font-size: 14px;
							color: #333;
							height: 30px;
							line-height: 30px;
							border-bottom: dashed 1px #c9c9c9;
						}
					}
					.text{
						font-size: 16px;
						color: #1e5b8e;
						margin-bottom: 8px;
						span{
							margin-right: 15px;
						}
					}
					.img-c1{
						margin-top: 28px;
						height: 325px;
						width: 240px;
					}
					.img-c6{
						width: 137.5px;
						height: 125.5px;
					}
					.img-c2{
						margin-top: 28px;
						width: 242.6px;
						height: 330px;
					}
					.img-c5{
						margin-top: 8px;
						width: 242px;
						height: 159.5px;
					}
					.img-c3{
						margin-top: 28px;
						width: 244.5px;
						height: 330px;
					}
					.img-c4{
						margin-top: 28px;
						width: 242.5px;
						height: 338.5px;
					}

				}
			}
			.step-item-text-2{
				height: 84px;
			}
			.step-item-text-611{
				height: 611px;
			}
			.step-item-text-221{
				height: 221px;
			}
			.step-item-text-586{
				height: 586px;
			}
			.step-item-text-231{
				height: 231px;
			}
			.step-item-text-588{
				height: 588px;
			}
			.step-item-text-430{
				height: 430px;
			}
			.step-item-text-600{
				height: 705px;
			}

		}
	}
}


</style>
