import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'lib-flexible';
import '../public/iconfont/iconfont.css';
import animate from 'animate.css'
import Vant from 'vant';
import axios from 'axios';
import BaiduMap from 'vue-baidu-map'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})


import WXConfig from './weixin/wxsdk' // 微信分享
Vue.prototype.WXConfig = WXConfig

Vue.use(BaiduMap, {
  ak: 'AwYQC2FFQGeHNxBLpnFTRAYufaK9lIOZ'
})




Vue.prototype.axios=axios;
Vue.config.productionTip = false;
Vue.use(Vant);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
