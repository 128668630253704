<template>
	<div class="stock-box">
		<div class="about-banner"></div>
		<div class="section-stock section">
			<div class="title-box">
				<div class="title">隐私条款</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>	
			<div class="stock-msg-box">
				<div class="container">
					
					
					以下隐私条款是沈阳盛世汇智网络科技有限公司（以下称“我i足球”、“我们”，或“我方”） 对用户隐私保护的许诺，请您务必仔细阅读本协议，以了解我们关于管理您个人信息的情况。
					本条款包括以下内容：</br>
					
					1.概述</br>
					我i足球 APP系由我司开发的App，与该APP相关的所有权利属于我们所有，我们尊重我i足球 APP访问者的隐私。您与我们之间的隐私条款旨在告知您关于我方收集、使用和披露您通过网站提交给我们的任何个人信息和匿名信息的条款和行为规范。</br>
					本条款所称“个人信息”是指您通过我i足球APP 上传的个人信息，如您的姓名、地址、电子邮件、手机号码、个人通过App获取的运动信息、其他使用者信息等，以及与上述内容相关的其他非公开信息。“非个人信息”是指与您的个人信息无关或无联系的信息；匿名信息不允许透露个人身份。</br>
					查看、访问和下载我i足球APP即表示您已阅读并理解本隐私条款的全文，且您同意本隐私条款的所有内容。</br>
					
					 
					2.用户同意</br>
					您通过本网站提交个人信息，即表示您同意本隐私条款的内容并明确同意按照本隐私条款对您的个人信息进行处理。我们对您的个人信息的处理可能在收集信息的所在国进行，也可能在其他国家进行，其他国家关于处理个人信息的法律可能没有您所在国家的法律严格。</br>
					从您那里收集的个人信息帮助我们提供App里面数据的展示，可帮助我们了解APP访客、我们的客户和潜在客户。通过使用这些信息，我们能够提供完整服务，以及更好地优化我们产品和服务的功能，并提供更好的支持，从而为您提供更多信息与更好的服务。</br>
					
					3.个人信息及使用</br>
					我们收集用户的两类信息：</br>
					（1）个人信息：</br>
					1）您向我们提供的个人信息</br>
					我们收集的个人信息是您通过本App自愿提供给我们的。当您发送电子邮件或者回复信息联系我们时，我们会收集您的姓名、性别、电话、电子邮件和电子邮件中包含的信息；当您使用我i足球 APP上传运动数据、运动视频和图像给我们时，我们会收集您的相关个人信息和运动数据。</br>
					2）凭借技术收集的个人信息</br>
					当您浏览本APP时，某些信息可能也会被被动收集，包括您的网络通信协议地址（“IP地址”）、浏览器类型、域名、访问时间以及操作系统。我们也会使用 “Cookie”和包括统一资源定位器（URL）在内的导航数据，来收集您的访问日期和时间的相关信息，以及您搜索和浏览的信息。收集这些类型的信息可以让我们的产品和服务为您提供更有针对性的帮助。在我们向您发送的信息或邮件中，有的会包含“点击URL”，尽可能为您提供方便。当您点击这些URL时，您将通过我们的网络服务器到达您的目标网站。通过追踪点击率，能帮助我们确定您对特定主题的兴趣，还能帮助我们衡量发送客户通信的有效性。</br>
					我们会在您自愿选择服务或提供信息的情况下收集您的个人信息，并将这些信息进行整合，以便向您提供更好的用户服务。请您在注册时及时、详尽及准确的提供个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。我们可能会将您通过自己的帐户提交的信息与我i足球 APP的其他服务或第三方的信息相结合，以便为您提供更好的体验并提高我们的服务质量。我i足球 APP收集这类关于个人身份的信息主要是为了用户能够更容易和更满意地使用我i足球 APP，而这些个人信息有助於我们实现这一目标。</br>
					（2）非个人信息：</br>
					当用户下载并使用我们的APP，我们收集和汇总诸如搜索请求、访问内容、访问的顺序以及链接途径等信息。收集这些信息涉及到记录访问我i足球 APP的每个用户的IP地址、操作平台、浏览器软件等。尽管这些信息无关个人身份，但我们能够通过其IP地址确定其使用的ISP和其上网的地理位置。这些无关个人身份的信息都帮助我们改进搜索结果并确定我们推广活动的有效性。</br>
					“IP地址” 是在您使用网络时向您的电脑自动分配的数字。在某些情况下，您的IP地址在浏览不同会话时是保持不变的；但是如果您使用的是消费者网络访问提供商，您的IP地址在不同会话时可能不同。我们仅追踪和会话Cookie相连的IP地址，用来分析我们网站的流量情况。</br>
					“Cookie”是在您浏览一个网站时，网站向您的电脑硬盘驱动器发送的一个小型信息片段。我们可能使用会话Cookie（一旦关闭网页浏览器便会失效）和持久性Cookie（会一直保存在您的电脑上直到您删除它们），从而向您提供更加私人化和更具互动性的网站体验。按照网络浏览器帮助文件指南，您就能删除持久性 Cookie。如果您选择禁用Cookie，网站的某些区域可能不会正常工作。</br>
					我们还会收集利用其本身无法与任何特定个人直接建立联系的数据。我们可出于合法目的而收集、使用、转让和披露非个人信息。下文是我们可能收集的非个人信息以及我们如何使用这些信息的一些示例。</br>
					我们会收集与客户在我i足球 APP中的活动有关的信息，以及从我们其他产品和服务获得的信息。我们会将此类信息汇总，用于帮助我们向客户提供更有用的信息，了解客户对我们网站、产品和服务中的哪些部分最感兴趣。就本隐私条款而言，汇总数据被视为非个人信息。</br>
					我们可能会收集和存储有关你如何使用我们的服务的详细信息，包括搜索查询。此类信息可能会用于改进我们的服务提供的结果，使其更具相关性。除了在少数情况下，我们需要确保通过互联网提供的服务的质量之外，此类信息不会涉及你的 IP 地址。</br>
					在你明确同意的情况下，我们可能会收集有关你如何使用设备和应用软件的数据，以帮助 APP开发者改进他们的APP。</br>
					 
					4.向第三方披露</br>
					我们将在出现下述情形时披露所收集的您的个人信息：</br>
					1）您授权或同意我们披露；</br>
					2）在紧急情况下，为了保护我i足球APP及其用户的合法权益或公共安全及利益；</br>
					3）根据法律规定或有权部门的要求提供您的个人信息；</br>
					根据我i足球APP各服务条款及声明中的相关规定，或者我们认为必要的其他情形下。</br>
					 
					5.个人信息的保护</br>
					本APP对于必要的个人信息，将通过技术手段进行保护，确保其安全性。另外按照本APP规定的管理标准严格管理个人信息，采取设置防火墙、配备杀毒软件、备份服务器数据等各种策略，以防止因病毒感染等所造成的个人信息的遗失、破坏、篡改、泄露。尽管我们有这些安全措施，但因特网上不存在“完善的安全措施”，因不可归责于中国足球协会的原因导致的个人信息的遗失、破坏、篡改、泄露我们将不承担责任。</br>
					6.个人信息的完整性和保留</br>
					我i足球 APP提供了一些便捷的方法，让你能够确保你的个人信息准确、完整并及时更新。我们将在实现本隐私条款中所述目的所必需的期间内保留你的个人信息，除非法律要求或允许在更长的期间内保留这些信息。</br>
					7.未成年人信息及使用</br>
					我们建议：任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称“监护人”）的书面同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。</br>
					对于使用我i足球APP产品和服务的未成年人，我们理解采取额外预防措施保护其隐私和安全的重要性。因此，除非收到可核实的家长同意书，我们不会有意收集、使用或披露不满 16周岁 (或相关司法辖区规定的类似最低年龄) 未成年人的个人信息。如果我们发现我们收集了年龄不满 16周岁 (或相关司法辖区规定的类似最低年龄) 未成年人的个人信息而事先未收到可核实的家长同意书，我们将采取措施尽快删除此等信息。限于我i足球 获取信息方式的有限性，我们认定未成年人的依据来源于用户自行在我i足球 APP上传的年龄信息，因此我们不能保证以上年龄的真实性。</br>
					8.安全与隐私</br>
					（1） 您有责任妥善保存你的密码。</br>
					（2）我i足球APP“隐私条款”是构成本使用条款的一部分。</br>
					9.链接 </br>
					我i足球APP上可能会放置第三方链接，我i足球 APP对其他任何第三方的内容、隐私条款或运营，或经营这些第三方网站或者软件的公司的行为概不负责。在向这些网站提供个人信息之前，请查阅它们的隐私条款。</br>
					10.更新与公开信息</br>
					我i足球APP鼓励用户更新和修改其个人信息以使其有效。我i足球 APP用户能在任何时候非常容易地获取并修改其个人信息。用户可以自行决定修改、删除他们的相关资料。</br>
					请记住，无论何时您自愿披露个人信息，此种信息可能被他人收集及使用，因此造成您的个人信息泄露，我们概不承担责任，如果你将个人信息公布在上述渠道，你就有可能造成个人信息泄露。因此，我们提醒并请您慎重考虑是否有必要在上述渠道公开您的个人信息。</br>
					最后，您是唯一对您的账号和密码信息负有保密责任的人。任何情况下，请小心妥善保管。</br>
					11.敏感权限获取相关说明 </br>
					1、定位权限</br>
					使用场景：赛事列表。</br>
					用途：用户筛选赛事时筛选本地的赛事使用定位便于用户筛选本地的赛事信息。</br>
					2、手机储存权限</br>
					使用场景：个人、资料界面、身份认证界面。</br>
					用途：用户选择手机存储图片、用户更改个人头像Logo信息时需要选择手机内存储的图片。</br>
					3、摄像头权限</br>
					使用场景：个人资料界面、身份认证界面。</br>
					用途：用户使用手机拍摄的照片用于修改自身的头像图片信息。</br>
					 
					

				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			
		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_PC"});
		}
	},
	mounted(){
		
	}
}
	
</script>

<style lang="scss" scoped>
.stock-box{
	.about-banner{
		height: 680px;
		background-color: #1E5B8E;	
		background-image: url(../assets/new_bg.png);
		background-size: cover;
		background-position: center;
	}
	.section{
		.title-box{
			position: relative;
			height: 136px;
			box-sizing: border-box;
			border-bottom: solid 2px #ccc;
			padding-top: 72px;
			.title{
				font-size: 30px;
				color: #666666;
				text-align: center;
			}
			.line-box{
				position: absolute;
				left: 0px;
				bottom: -4px;
				right: 0px;
				margin: auto;
				z-index: 1;
				height: 6px;
				width: 198px;
				.left-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #2e8bb6;
				}
				.right-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #80ab35;				
				}
			}			
		}		
	}	
	.section-stock{
		height: 4000px;
		width: 100%;
		.stock-msg-box{
			margin-top: 82px;
			height: 1000px;
			width: 100%;
			.container{
				width: 1200px;
				height: 410px;
				margin-left: auto;
				margin-right: auto;
				padding-top: 30px;
				font-size: 20px;
				color: #666;
				line-height: 40px;
				text-indent: 50px;
			}
		}
	}
	.product-box{
		height: 540px;
		background-image: url(../assets/1.jpg);
		background-position: center;
		background-size: cover;
		.title-box{
			border-bottom: solid 2px #fff;
			.title{
				color: #fff;
			}
			.line-box{
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;				
				}
			}			
		}
		.product-content{
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 404px;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
			.product-item{
				height: 240px;
				width: 240px;
				box-sizing: border-box;
				background-color: #fff;
				img{
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
				.main-title{
					line-height: 18px;
					font-size: 18px;
					text-align: center;
					color: #333;
				}
				.sub-title{
					margin-top: 14px;
					font-size: 14px;
					text-align: center;
					color: #666;
				}
			}
		}		
	}
	.example-box{
		width: 100%;
		.example{
			height: 570px;
			width: 100%;
			background-image: url(../assets/bg_c.png);
			.form-img{
				width: 1200px;
				height: auto;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
	}
	.step-box{
		position: relative;
		width: 100%;
		background-size: cover;
		background-image: url(../assets/stepbg.jpg);
		background-position: center;
		.img-step{
			cursor: pointer;
			width: 1203px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		
	}
	.data-box{
		.img-data{
			display: block;
			width: 1200px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}	

}


</style>