<template>
	<div class="primary-box">
		<van-swipe :autoplay="3000" indicator-color="white" class="banner">
			<van-swipe-item v-for="(item, index) in sliderList" :key="index">
				<img class="banner-img" :src="item.img"/>
			</van-swipe-item>
		</van-swipe>
		<div class="business-section">
			<div class="title-box">
				<div class="text-box">
					业务介绍
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>

			<div class="business-box">
				<div class="img-box">
					<div class="video">
						 <!-- 解决移动端video标签默认置顶 -->
            <video object-fit: fill autoplay:false preload="auto" x5-video-player-type="h5" x5-video-player-fullscreen="true" x-webkit-airplay="true" webkit-playsinline="" playsinline="" tabindex="2" mediatype="video" controls="controls" src="https://aym-1300578978.cos.ap-beijing.myqcloud.com/unstructured/impawning/company/hyr.mp4" width="100%"></video>
					</div>
				</div>
				<div class="title-line">
					<div class="left-title">
						<div class="iconfont icon-stock"></div>
						<div class="msg">存货质押</div>
					</div>
				</div>
				<div class="img-box" @click="goDetail('Stock_M')">
					<img class="business-img" src="../assets/bm01.png"/>
					<div class="more-btn">点击了解详情</div>
				</div>
				<div class="title-line">
					<div class="left-title">
						<div class="iconfont icon-bill"></div>
						<div class="msg">应收账款</div>
					</div>
				</div>
				<div class="img-box" @click="goDetail('Account_M')">
					<img class="business-img" src="../assets/bm02.png"/>
					<div class="more-btn">点击了解详情</div>
				</div>
			</div>


		</div>
		<div class="product-box">
			<div class="title-box">
				<div class="text-box">
					产品特点
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<div class="img-box">
				<img class="product-img" src="../assets/pm1.png"/>
				<img class="product-img" src="../assets/pm2.png"/>
				<img class="product-img" src="../assets/pm3.png"/>
			</div>

		</div>

		<div class="technology-section">
			<div class="title-box">
				<div class="text-box">
					技术优势
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>

			<div class="technology-box">
				<van-swipe :autoplay="3000" class="technology-banner" :show-indicators="false">
					<van-swipe-item v-for="(item, index) in sliderList2" :key="index">
						<img class="banner-img" :src="item.img"/>
					</van-swipe-item>
				</van-swipe>
				<div class="button" @click="apply">立即申请</div>
			</div>
		</div>

		<div class="news-section">
			<div class="title-box">
				<div class="text-box">
					新闻资讯
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>

			<div class="news-item" v-for="(item,index) of newsList" @click="goNewsDetail(item.newsId)" :key="item.newsId">
				<div class="left-side">
					<div class="date">{{item.createTime | getMonth}}</div>
					<div class="year">{{item.createTime | getYear}}</div>
				</div>
				<div class="right-side">
					<div class="title">{{item.newsTitle}}</div>
					<div class="content">{{item.newsMessageText}}</div>
				</div>
			</div>

			<div class="button" @click="goNewsList">查看更多</div>
		</div>

		<div class="cooperation-section">
			<div class="title-box">
				<div class="text-box">
					合作机构
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<div class="cooperation-box">
				<a href="http://www.lbsr.cn/" target="_blank">
					<img class="ad-img" src="../assets/adm2.png"/>
				</a>
				<a href="https://www.shenyitao.com" target="_blank">
					<img class="ad-img" src="../assets/adm3.png"/>
				</a>
				<a href="http://www.bankofbx.com/" target="_blank">
					<img class="ad-img" src="../assets/adm4.png"/>
				</a>




			</div>




		</div>



		<v-footer></v-footer>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法
import VFooter from "./components/Footer.vue"
export default {
  name: 'home',
	components:{
		VFooter,
	},
  data(){
  	return {
		navShow:false,
		autoFlag:true,
		indexFlag:true,
		msgFlag:false,
		differ:"1",
		sliderList:[
			{"img":require("../assets/bm-1.png"),"message":"0"},
			{"img":require("../assets/bm-2.png"),"message":"1"},
			{"img":require("../assets/bm-3.png"),"message":"2"},
			{"img":require("../assets/bm-4.png"),"message":"3"},
			{"img":require("../assets/bm-5.png"),"message":"4"},
		],
		autoFlag2:true,
		indexFlag2:false,
		msgFlag2:false,
		differ2:"2",
		sliderList2:[
			{"img":require("../assets/tm-1.png"),"message":"0"},
			{"img":require("../assets/tm-2.png"),"message":"1"},
		],
		newsList:[],
	}
  },
	filters:{
		getMonth(val){
			return val.substr(val.length-5).replace('-','/');
		},
		getYear(val){
			return val.substring(0, 4)
		}
	},
  methods:{
  	goDetail(name){
  		this.$router.push({name});
  		},
	apply(){
		this.$router.push({name:"Apply_M"});
	},
	goNewsList(){
		this.$router.push({name:"NewsList_M"});
	},
	getNewsList(pageNo,pageSize){
		api.post('/hyr/user/news/getNewsMessageList?pageNo=1&pageSize=2', {
			pageNo,pageSize
		}).then((res) => {
			if(res.data.status){
				this.newsList=res.data.resultBody.message;
			}else{
				console.log("没有了")
			}
		}).catch((res) => {})
	},
	  goNewsDetail(newsId){
  		this.$router.push({name:"NewsDetail_M",query:{newsId}});
  		},
  },
  mounted(){
  	this.getNewsList(1,2);
    this.WXConfig.wxShowMenu();
  },
}
</script>

<style lang="scss" scoped>
.title-box{
	height: 54px;
	width: 100%;
	border-bottom: solid 1px #cccccc;
	.text-box{
		position: relative;
		width: 100px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		line-height: 54px;
		font-size: 20px;
		color: #666666;
		.left-line{
			position: absolute;
			bottom: -2px;
			left: 0px;
			width: 50.5px;
			height: 3px;
			background-color: #2e8bb6;
		}
		.right-line{
			position: absolute;
			bottom: -2px;
			right: 0px;
			width: 50.5px;
			height: 3px;
			background-color: #80ab35;
		}
	}
}
.primary-box{
	width: 100%;
	overflow:hidden;
	.banner{
		width: 100%;
		height: 230px;
		.banner-img{
			width: 100%;
			height: 230px;
		}
		/deep/ .van-swipe__indicators{
			margin-bottom: 4px;
			.van-swipe__indicator{
				background-color: rgba(0,0,0,0.7);
				height: 10px;
				width: 10px;
				border-radius: 50%;
				opacity: 1;
			}
		}
	}
	.business-section{
		padding-top: 20px;
		.business-box{
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			.title-line{
				margin-top: 10px;
				height: 42px;
				border-bottom: solid 1px #cccccc;
				.left-title{
					float: left;
					height: 42px;
					width: 102px;
					border-bottom: solid 1px #2e8bb6;
					.icon-stock{
						float: left;
						line-height: 42px;
						font-size: 26px;
						color: #333;
					}
					.icon-bill{
						float: left;
						line-height: 42px;
						font-size: 26px;
						color: #333;
					}
					.msg{
						line-height: 42px;
						font-size: 15px;
						color: #333;
						float: right;
						padding-right: 4px;
					}
				}
			}
			.img-box{
				padding-top: 19px;
				box-sizing: border-box;
				.business-img{
					width: 100%;
					display: block;
				}
				.more-btn{
					height: 39px;
					text-align: center;
					line-height: 39px;
					font-size: 15px;
					color: #666;
					border: solid 1px #ae9e87;
					border-top: none;
					margin-bottom: 30px;
				}
			}
		}
	}
	.product-box{
		height: 209px;
		background-image: url(../assets/bg-m1.png);
		background-position: center;
		.title-box{
			border-bottom: solid 1px #fff;
			.text-box{
				color: #fff;
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;
				}
			}

		}
		.video-box{
			width: 100%;
			.video{
				margin: 0 auto;
			}

		}
		.img-box{
			display: flex;
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			padding-top: 26px;
			justify-content: space-between;
			.product-img{
				width: 100px;
				height: 107px;
			}
		}

	}
	.technology-section{
		.technology-box{
			padding-top: 40px;
			padding-bottom: 20px;
			padding-left: 17.5px;
			padding-right: 17.5px;
			.technology-banner{
				width: 340px;
				height: 191px;
				margin-left: auto;
				margin-right: auto;
				.banner-img{
					width: 340px;
					height: 191px;
				}
			}
			.button{
				width: 120px;
				height: 40px;
				background-color: #1e5b8e;
				color: #fff;
				font-size: 16px;
				text-align: center;
				line-height: 40px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 40px;
			}
		}
	}
	.news-section{
		.news-item{
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			height: 112px;
			&:nth-child(odd){
				background-color: #f6f6f6;
			}
			.left-side{
				float: left;
				box-sizing: border-box;
				padding-top: 45px;
				width:51px;
				.date{
					color: #333;
					font-size: 15px;
					line-height: 15px;
				}
				.year{
					line-height: 14px;
					font-size: 14px;
					color: #999;
				}
			}
			.right-side{
				float: left;
				width: 280px;
				box-sizing: border-box;
				padding-top: 26px;
				.title{
					font-size: 15px;
					color: #333;
					font-weight: bold;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
				.content{
					margin-top: 4px;
					color: #666;
					font-size: 14px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
		.button{
			width: 120px;
			height: 40px;
			background-color: #1e5b8e;
			color: #fff;
			font-size: 16px;
			text-align: center;
			line-height: 40px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
			margin-bottom: 18px;
		}
	}
	.cooperation-section{

		.cooperation-box{
			box-sizing: border-box;
			padding-left: 17.5px;
			padding-right: 17.5px;
			padding-top: 30px;
			padding-bottom: 42px;
			display: flex;
			justify-content: space-between;
			.ad-img{
				display: block;
				height: 50px;
				width: 100px;
			}
		}
	}
}



</style>
