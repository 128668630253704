<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
export default {
  methods:{
		_isMobile() {
			//判断当前环境是pc端还是移动端
			 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			 return flag;
		}
  },
  mounted(){
//	let routeName=this.$route.name;
//  if (this._isMobile()) {
//    this.$router.replace('/home1');
//  } else {
//    this.$router.replace({name:routeName});
//  }  	
//  if (this._isMobile()) {
//    this.$router.replace('/home1');
//  } else {
//    this.$router.replace('/home_pc');
//  } 	
  }
}
</script>

<style lang="scss">
html{
	margin: 0px;
	padding: 0px;
}
body{
	margin: 0px;
	padding: 0px;
	font-family: "microsoft yahei";
}
#app{
	width: 100%;
}
</style>
