<template>
	<div class="about-box">
		<div class="about-banner"></div>
		
		<div class="wraper">
			
			<div class="title-box">
				<div class="title">联系我们</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>

			<div class="contact-box">
				<div class="left-side">
					<div class="info">电话：18211085165（周一到周五8:30-17:00）</div>
					<div class="info">邮箱：lin_0711@163.com</div>
					<div class="info">邮编：110100</div>
					<div class="info">地址: 辽宁省沈阳市浑南区五三街道营盘西街31号</div>
				</div>
				<div class="right-side">
					<template>
					  <baidu-map class="map" id="map">
					  </baidu-map>
					</template>
				</div>
			</div>
		</div>


	</div>
</template>
<style>

</style>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法
import BaiduMap from '../tools/baidu-map.js';
export default {
	data() {
		return {
			selType:0,
		}
	},
	methods: {
		createMap(){
			let map = new BMap.Map("map"); // 创建Map实例
			map.centerAndZoom(new BMap.Point(123.464761,  41.735606), 13); // 初始化地图,设置中心点坐标和地图级别
			//添加地图类型控件
			map.addControl(new BMap.MapTypeControl({
				mapTypes: [
					BMAP_NORMAL_MAP,
					BMAP_HYBRID_MAP
				]
			}));
			map.setCurrentCity("沈阳"); // 设置地图显示的城市 此项是必须设置的
			map.enableScrollWheelZoom(true);

			let marker = new BMap.Marker(new BMap.Point(123.464761, 41.735606)); // 创建标注
			map.addOverlay(marker);
			marker.addEventListener("click", function() {
				map.openInfoWindow(infoWindow, map.getCenter());
			});
			var opts = {
				width: 100, // 信息窗口宽度
				height: 50, // 信息窗口高度
			}
			var infoWindow = new BMap.InfoWindow("<p>地址：辽宁省沈阳市浑南区五三街道营盘西街31号</p>", opts); // 创建信息窗口对象
			map.openInfoWindow(infoWindow, map.getCenter());
		}
	},
	mounted() {
		this.createMap();
//		BaiduMap.init().then((BMap) => {
//			console.log("不好用")
//		})

	}
}
</script>

<style lang="scss" scoped>
	.right-side1{
		height: inherit;
		padding-left: 10px;
		width: 312px;
		float: left;
		padding-top: 26px;
		.msg-item1{
			width: 326px;
			height: 40px;
			font-size: 10px;
			color: #333;
			line-height: 10px;
			border-bottom: dashed 1px #c9c9c9;
		}
	}
    .left-img2{
		float: left;
		margin-left: 34px;
		margin-right: 30px;
		width: 207px;
		height: 287px;
		margin-top: 50px;
		border: 1px solid #b3d4fc;
	}

.about-box{
	.about-banner{
		height: 680px;
		background-color: #1E5B8E;
		background-image: url(../assets/new_bg.png);
		background-size: cover;
		background-position: center;
	}
	.tab-box{
		height: 80px;
		background-color: #F6F6F6;
		.container{
			height: 80px;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
			.tab-item{
				user-select: none;
				cursor: pointer;
				float: left;
				height: 80px;
				box-sizing: border-box;
				width: 160px;
				text-align: center;
				font-size: 18px;
				line-height: 78px;
				color: #666;
				transition: all 0.35s;
				border-bottom: solid 2px #F6F6F6;
				&:hover{
					color: #2e8bb6;
				}
			}
			.tab-active{
				border-bottom-color: #2e8bb6;
			}
		}
	}
	.title-box{
		position: relative;
		height: 136px;
		box-sizing: border-box;
		border-bottom: solid 2px #ccc;
		padding-top: 72px;
		.title{
			font-size: 30px;
			color: #666666;
			text-align: center;
		}
		.line-box{
			position: absolute;
			left: 0px;
			bottom: -4px;
			right: 0px;
			margin: auto;
			z-index: 1;
			height: 6px;
			width: 198px;
			.left-line{
				float: left;
				width: 94px;
				height: 6px;
				background-color: #2e8bb6;
			}
			.right-line{
				float: left;
				width: 94px;
				height: 6px;
				background-color: #80ab35;
			}
		}
	}
	.introduce-box{
		margin-top: 80px;
		height: 220px;
		background-color: #f6f6f6;
		.container{
			width: 1200px;
			height: 220px;
			box-sizing: border-box;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 10px;
			.introduce-img{
				width: 480px;
				height: 220px;
				float: left;
				display: block;
			}
			.message-box{
				margin-top: 10px;
				float: right;
				width: 600px;
				height: 200px;
				box-sizing: border-box;
				padding-top: 55px;
				font-size: 18px;
				color: #666;
				line-height: 28px;
			}
		}
	}
	.step-box{
		height: 530px;
		background-image: url(../assets/fzlcbg.jpg);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		.container{
			width: 1200px;
			height: 530px;
			margin-left: auto;
			margin-right: auto;
			padding-top: 34px;
			.step-img{
				display: block;
				width: 1199px;
				height: auto;
				position: relative;
				right: 55px;
				top: -12px;
			}
		}
	}
	.culture-box{
		height: 532px;
		width: 1202px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 40px;
		.culture-img{
			height: 532px;
			width: 1202px;
		}
	}
	.contact-box{
		height: 490px;
		width: 1200px;
		box-sizing: border-box;
		padding-top: 76px;
		margin-left: auto;
		margin-right: auto;
		.left-side{
			width: 658px;
			height: 334px;
			float: left;
			align-content: space-around;
			flex-wrap: wrap;
			display:flex;
			.info{
				width: inherit;
				font-size: 20px;
				color: #666;
			}
		}
		.right-side{
			width: 542px;
			height: 334px;
			float: right;
			.map{
				height: inherit;
				width: inherit;
			}
		}
	}

	.step-list {
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 80px;

		.step-item {
			position: relative;
			width: 1px;
			height: 104px;
			background-color: #ccc;
			margin-left: auto;
			margin-right: auto;

			.year-box {
				position: absolute;
				top: -13px;
				left: -13px;
				height: 26px;
				width: 400px;

				.year-pointer {
					background-color: #fff;
					float: left;
					height: 26px;
					width: 26px;
					border-radius: 50%;
					border: solid 1px #2e8bb6;
					justify-content: center;
					align-items: center;
					display: flex;

					.inner-pointer {
						height: 14px;
						width: 14px;
						border-radius: 50%;
						background-color: #2e8bb6;
					}
				}

				.year-num {
					float: left;
					padding-left: 20px;
					font-size: 20px;
					color: #333;
					font-weight: bold;
				}
			}

			.left-text-box {
				position: absolute;
				left: -400px;
				height: 104px;
				width: 400px;
				box-sizing: border-box;
				padding-right: 30px;

				.pointer {
					position: absolute;
					top: 2px;
					right: -8px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}

				.info {
					font-size: 18px;
					color: #1e5b8e;
					text-align: right;
					line-height: 18px;
					margin-bottom: 14px;
				}
			}

			.right-text-box {
				position: absolute;
				height: 104px;
				width: 400px;
				box-sizing: border-box;
				left: 1px;
				padding-left: 30px;

				.pointer {
					position: absolute;
					top: 2px;
					left: -7px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}

				.info {
					font-size: 18px;
					color: #1e5b8e;
					text-align: left;
					line-height: 18px;
					margin-bottom: 14px;
				}
			}
		}

		.big-img-item {
			height: 430px;
			.left-box {
				position: absolute;
				left: -600px;
				width: 600px;
				height: 430px;

				.pointer {
					position: absolute;
					top: 2px;
					right: -8px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}

				.info {
					padding-right: 30px;
					font-size: 18px;
					color: #1e5b8e;
					text-align: right;
					line-height: 18px;
				}

				.msg-box {
					height: 340px;
					margin-top: 12px;
					.left-side {
						height: inherit;
						padding-left: 10px;
						width: 312px;
						float: left;
						.msg-item {
							width: 326px;
							height: 52px;
							font-size: 14px;
							color: #333;
							line-height: 60px;
							border-bottom: dashed 1px #c9c9c9;
						}
					}
					.left-side-5 {
						padding-top: 26px;
					}
					.right-img {
						float: left;
						margin-left: 40px;
						width: 207px;
						height: 287px;
						margin-top: 28px;
					}
				}
			}

			.right-box {
				position: absolute;
				left: 1px;
				width: 600px;
				height: 430px;
				.pointer {
					position: absolute;
					top: 2px;
					left: -7px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}
				.info {
					padding-left: 30px;
					font-size: 18px;
					color: #1e5b8e;
					text-align: left;
					line-height: 18px;
				}
				.msg-box {
					height: 340px;
					margin-top: 12px;
					.right-side {
						height: inherit;
						padding-left: 10px;
						width: 312px;
						float: left;
						padding-top: 26px;
						.msg-item {
							width: 326px;
							height: 52px;
							font-size: 14px;
							color: #333;
							line-height: 60px;
							border-bottom: dashed 1px #c9c9c9;
						}
					}
					.left-img {
						float: left;
						margin-left: 34px;
						margin-right: 30px;
						width: 207px;
						height: 287px;
						margin-top: 28px;
					}
				}
			}
		}

		.normal-img-item {
			height: 300px;
			.right-box {
				position: absolute;
				left: 1px;
				width: 600px;
				height: 300px;
				.pointer {
					position: absolute;
					top: 2px;
					left: -7px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}
				.info {
					padding-left: 30px;
					font-size: 18px;
					color: #1e5b8e;
					text-align: left;
					line-height: 18px;
				}
				.img-box {
					.gold {
						width: 113px;
						height: 108px;
						float: left;
						margin-left: 30px;
						margin-top: 38px;
					}
					.left-img {
						display: block;
						width: 241px;
						height: 159px;
						float: left;
						margin-left: 34px;
						margin-top: 40px;
					}
					.right-img {
						margin-top: 40px;
						margin-left: 84px;
						width: 220px;
						height: 161px;
						float: left;
					}
				}
			}

			.left-box {
				position: absolute;
				right: 1px;
				width: 600px;
				height: 300px;
				.pointer {
					position: absolute;
					top: 2px;
					right: -8px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}
				.info {
					padding-right: 30px;
					font-size: 18px;
					color: #1e5b8e;
					text-align: right;
					line-height: 18px;
				}
				.img-box {
					.fire-img {
						display: block;
						float: right;
						height: 283px;
						width: 201px;
						margin-right: 30px;
						margin-top: 40px;
					}
				}
			}
		}
		.normal-img{
			height: 400px;
		}
		.normal-long-item {
			height: 650px;
			background-color: #fff;
			.right-box {
				position: absolute;
				left: 1px;
				width: 600px;
				height: 430px;
				.pointer {
					position: absolute;
					top: 2px;
					left: -7px;
					height: 14px;
					width: 14px;
					border-radius: 50%;
					background-color: #2e8bb6;
				}
				.info {
					padding-left: 30px;
					font-size: 18px;
					color: #1e5b8e;
					text-align: left;
					line-height: 18px;
				}
				.msg-box {
					height: 340px;
					margin-top: 12px;
					.right-side-box {
						height: inherit;
						padding-left: 10px;
						width: 312px;
						float: left;
						padding-top: 26px;
						.msg-item {
							width: 340px;
							height: 52px;
							font-size: 14px;
							color: #333;
							line-height: 60px;
							border-bottom: dashed 1px #c9c9c9;
						}
					}
					.left-img {
						float: left;
						margin-left: 34px;
						margin-right: 30px;
						width: 207px;
						height: 287px;
						margin-top: 120px;
					}
				}
			}
		}
	}


}
</style>
