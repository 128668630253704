<template>
	<div class="pagination-box" id="page-break">
	  <ul class="list" :class="{'no-border':!borderShow}">
	  	<!--回到第一页-->
	  	<li class="item" v-if="doubleShow">
	  		<a  class="double" @click="goFirst">«</a>
	  	</li>
	  	<!--上一页-->
	    <li v-if="cur>1" class="item">
	      <a v-on:click="cur--,pageClick()"><</a>
	    </li>
	    <li v-if="cur==1" class="item">
	      <a class="banclick"><</a>
	    </li>
	    <template v-if="allpage>5">
		    <li v-if="cur>=4" class="item">
		      <a v-on:click="btnClick(1), pageClick()">1</a>
		    </li>	    
		    <li v-if="cur>=5&&allpage>6" class="item-more">
		      <a >...</a>
		    </li>
	    </template>
	    
	    <li v-for="item in indexs" v-bind:class="{'active':cur==item}" class="item">
	      <a v-on:click="btnClick(item), pageClick()">{{item}}</a>
	    </li>
	    
	    <template v-if="allpage>5">
		    <li v-if="cur<=allpage-3&&allpage>6&&cur+3!=allpage" class="item-more">
		      <a >...</a>
		    </li>		    
		    <li v-if="cur<=allpage-3" class="item">
		      <a v-on:click="btnClick(allpage), pageClick()">{{allpage}}</a>
		    </li>
	    </template>
	    
	    <!--下一页-->
	    <li v-if="cur!=allpage" class="item">
	      <a v-on:click="cur++,pageClick()">></a>
	    </li>
	    <li v-if="cur==allpage" class="item">
	      <a class="banclick">></a>
	    </li>
	    
	    <!--回到最后一页-->
	  	<li class="item" v-if="doubleShow">
	  		<a class="double" @click="goLast">»</a>
	  	</li>	
	  	
	    <div class="jumpbox" v-if="inputshow">
			<input type="number" v-model="jumpPage" class="jumppage" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"/>
			<a class="jumpbtn" v-on:click="pageSkip()">GO</a>
		</div>
	  </ul>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				cur:1,
				jumpPage:""
			}
		},
		props:{
			allpage:{
				type:Number,
				required: true,
				validator:function(value){
					return value>=0
				}
			},
			doubleShow:{
				type:Boolean,
				default:false				
			},
			inputshow:{
				type:Boolean,
				default:true
			},
			borderShow:{
				type:Boolean,
				default:true				
			},			
			currentPage:{
				type:Number,
				default:1
			},			
		},
		methods: {
			btnClick(num){
				if(num!=this.cur){
					this.cur=num;
				}
			},
			pageClick(){
				//将当前页面传递给父组件
				this.$emit("getPageNumber",this.cur)
			},
			pageSkip(){
				let maxPage = this.allpage;
				let skipPage = parseInt(this.jumpPage);
				if(skipPage<1){
					this.jumpPage=1
					this.btnClick(1);
					this.pageClick();					
					return;
				}else if(skipPage>maxPage){
					this.jumpPage=maxPage;
					this.btnClick(maxPage);
					this.pageClick();
					return;
				}else if(isNaN(skipPage)){
					return;
				}else{
					this.btnClick(skipPage);
					this.pageClick();
				}
			},
			goLast(){
				if(this.cur==this.allpage){
					return;
				}else{
					this.jumpPage=this.allpage
					this.btnClick(this.allpage);
					this.pageClick();						
				}
			},
			goFirst(){
				if(this.cur==1){
					return;
				}else{
					this.jumpPage=1
					this.btnClick(1);
					this.pageClick();					
				}
				
			},
		},
		watch:{
			currentPage(){
				this.cur=this.currentPage;
			}
		},
		computed: {
			indexs(){
				var left = 1;
				var right = this.allpage;
				var arr = [];
				if(this.allpage>=5){
					if(this.cur>3 && this.cur<this.allpage-2){
						left = this.cur-2;
						right = this.cur+2;
					}else if(this.cur<=3){
	                    left=1;
	                    right=5;
					}else{
						left=this.allpage-4;
						right=this.allpage;
					}
				}
				while(left<=right){
					arr.push(left);
					left++;
				}
				return arr;
			}
		},
		mounted(){
			this.cur=this.currentPage;
		},
		updated(){
			this.cur=this.currentPage;
		}
	
	}
</script>

<style lang="scss" scoped>
/*Chrome浏览器去箭头*/
input[type='number']::-webkit-outer-spin-button,input[type='number']::-webkit-inner-spin-button{
	-webkit-appearance: none !important;
	margin: 0px;
}
/*Firefox浏览器去箭头*/
input[type="number"]{-moz-appearance:textfield;}
.pagination-box{
	overflow: hidden;
	/*min-width: 735px;*/
  	.list{
  		margin: 0px;
  		padding: 0px;
  		.item{
  			list-style: none;
  			cursor: pointer;
  			height: 35px;
  			width: 35px;
  			border: solid 1px #D7D7D7;
  			border-radius: 3px;
  			float: left;
  			margin-right: 4px;
  			box-sizing: border-box;
  			&:hover{
  				border-color: #6CAECC;
  				a{
  					color: #6CAECC;
  				}
  			}
  			a{
  				font-family: 'Arial Normal', 'Arial';
  				font-weight: normal;
  				font-size: 14px;
  				display: block;
  				line-height: 33px;
  				text-decoration: none;
  				text-align: center;
  				color: #999999;
  				-webkit-user-select: none;
  				-ms-user-select: none;
  				-moz-user-select: none;
  			}
  			.double{
  				font-size: 16px;
  			}
  		}
  		.active{
  			border-color: #6CAECC;
  			background-color: #6CAECC;
  			a{
  				color: #fff !important;
  			}
  		}
  		.item-more{
  			list-style: none;
  			height: 35px;
  			width: 35px;
  			border: solid 1px #D7D7D7;
  			border-radius: 3px;
  			float: left;
  			margin-right: 4px;
  			box-sizing: border-box;
  			a{
  				font-family: 'Arial Normal', 'Arial';
  				font-weight: normal;
  				font-size: 14px;
  				display: block;
  				line-height: 33px;
  				text-decoration: none;
  				text-align: center;
  				color: #999999;
  				-webkit-user-select: none;
  				-ms-user-select: none;
  				-moz-user-select: none;  				
  			}  			
  		}
  		.jumpbox{
  			height: 35px;
  			float: left;
  			margin-left: 6px;
  			.jumppage{
  				float: left;
  				box-sizing: border-box;
  				border-radius: 3px;
  				border: solid 1px #D7D7D7;
	  			width: 45px;
	  			height: 35px;
	  			line-height: 35px;
	  			text-align: center;
	  			&:focus{
	  				outline: none;
	  				border-color: #6CAECC;
	  			}
  			}
  			.jumpbtn{
  				font-family: 'FontAwesome Regular', 'FontAwesome';  
  				width: 35px;
	  			height: 35px;
  				float: left;
  				margin-left: 4px;
  				background-color: #6CAECC;
  				display: inline-block;
  				border-radius: 3px;
  				color: #fff;
  				text-align: center;
  				line-height: 35px;
  				-webkit-user-select: none;
  				-ms-user-select: none;
  				-moz-user-select: none;
  				cursor: pointer;				
  			}
  		}
  		
  	}
  	.no-border{
  		.item{
  			border: none !important;
  			height: 30px;
  			width: 30px;
  			border-radius: 5px;
  			a{
  				line-height: 30px;
  			}
  		}
  		.item-more{
  			border: none !important;
  			height: 30px;
  			width: 30px;
  			border-radius: 5px; 
  			a{
  				line-height: 30px;
  			}  			 			
  		}

  	}	
}  
 


</style>