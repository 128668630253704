<template>
	<div class="container-pc" id="container-pc">
		<div class="header" :class="{'active-header':headerFlag,'active-top':isTop}">
			<div class="nav-box">
				<!-- <img class="logo" src="../assets/logo.png"/> -->
				<div class="logo">沈阳盛世汇智网络科技有限公司</div>
				<div class="nav-list"  @click="selType">
					<div class="nav-item" :class="{'active-nav':$route.path.indexOf('primary_pc')!==-1}" data-value = "Primary_PC">首页</div>
					<div class="nav-item" :class="{'active-nav':$route.path.indexOf('stock_pc')!==-1}" data-value = "Stock_PC">品牌介绍</div>
					<!-- <div class="nav-item" :class="{'active-nav':$route.path.indexOf('account_pc')!==-1}" data-value = "Account_PC">应收账款</div> -->
					<!--<div class="nav-item" :class="{'active-nav':$route.path.indexOf('technology_pc')!==-1}" data-value = "Technology_PC">技术优势</div>-->
					<div class="nav-item" :class="{'active-nav':$route.path.indexOf('about_pc')!==-1}" data-value = "About_PC">联系我们</div>
					<!-- <div class="nav-item" :class="{'active-nav':$route.path.indexOf('recruit_pc')!==-1}" data-value = "Recruit_PC">人才招聘</div> -->
				</div>
			</div>
		</div>
		
		<div class="router-box">
			<router-view></router-view>
		</div>
		
		<!-- <div class="suspend-nav">
			<div class="nav-item" @click="send">
				<div class="iconfont icon-qq"></div>
				<div class="text">在线客服</div>
			</div>
			<div class="nav-item">
				<div class="iconfont icon-scan"></div>
				<div class="text">二维码</div>	
				<img class="code-img" src="../assets/code.jpg"/>
			</div>
			<div class="nav-item" @click="goUp">
				<div class="iconfont icon-top"></div>
				<div class="text">回到顶部</div>				
			</div>
		</div> -->
		
		
		
		<div class="footer">
			<div class="content">
				<div class="left-side">
					<div class="nav-line">
						<span class="left-text" @click="goto('Primary_PC')">首页</span>
						<span class="right-text" @click="goto('About_PC')">关于我们</span>
					</div>
					<!-- <div class="nav-line">
						<span class="left-text" @click="goto('Apply_PC')">服务申请</span>
						<span class="right-text" @click="goMg">后台管理</span>
					</div> -->
					
				</div>
				<div class="middle-side">
					<div class="info">地址: 辽宁省沈阳市浑南区五三街道营盘西街31号</div>
					<div class="info">邮箱 : lin_0711@163.com</div>
					<div class="info tel">电话 : 18211085165（周一到周五8:30-17:00）</div>
					<div class="info">
						沈阳盛世汇智网络科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">辽ICP备2024022835号-2</a>
					</div>
				</div>
				<!-- <img class="right-side" src="../assets/code.jpg"/> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data(){
  	return {
  		headerFlag:false,
  		isTop:true,
  	}
  },
	watch:{
	  $route(to,from){
	    this.headerFlag=false;
	    this.goUp();
	  }
	},  
  methods:{
	selType(e){
		let routeName=e.srcElement.dataset.value;
		if(routeName){
			
			if(routeName==='Recruit_PC'){
				this.$router.push({name:routeName,query:{page:1}});
			}else{
				this.$router.push({name:routeName});
			}

		}
		
	},  	
	goUp(){
	    let nowTop = document.body.scrollTop || document.documentElement.scrollTop; // 获取当前滚动条位置
	    if (nowTop > 0) {
	        window.requestAnimationFrame(this.goUp);
	        window.scrollTo (0,nowTop - (nowTop/5));
	    }		
	},
	goto(name){
		this.$router.push({name});
	},
	goMg(){
		window.location.replace("https://www.5iher.com/hyrhd/#/login");
	},
    handleScroll() {
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;  
        let scroll = document.documentElement.scrollTop || document.body.scrollTop;
        if(scroll==0){
        	this.isTop=true;
        }else{
        	this.isTop=false;
        }
        
        switch (this.$route.name){
        	case "Primary_PC":
        		if(scroll>690){
        			this.headerFlag=true;
        		}else{
        			this.headerFlag=false;
        		}
        		break;
        	default:
        		if(scroll>480){
        			this.headerFlag=true;
        		}else{
        			this.headerFlag=false;
        		}        	
        		break;
        }
//      console.log(scroll,this.$route.name)
     
    },
	_isMobile() {
		//判断当前环境是pc端还是移动端
		 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		 return flag;
	},
	send(){
		let qq = "529600625";//客服qq号
		window.open('http://wpa.qq.com/msgrd?v=3&uin='+qq+'&site=qq&menu=yes','_brank');
	}
  },
  mounted(){
    if (this._isMobile()) {
      this.$router.replace('/home_m');
    }  	
	window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
  },
  destroyed(){
  	window.removeEventListener('scroll', this.handleScroll);   //  离开页面清除（移除）滚轮滚动事件
  }
}
</script>

<style lang="scss" scoped>
.container-pc{
	min-width: 1200px;
	/*width: 100%;*/
	.router-box{
		
	}
	.suspend-nav{
		position: fixed;
		z-index: 1;
		top: 342px;
		right: 80px;
		height: 300px;
		width: 102px;
		.nav-item{
			position: relative;
			user-select: none;
			cursor: pointer;
			text-align: center;
			height: 100px;
			width: inherit;
			box-sizing: border-box;
			border-bottom: solid 1px #fff;
			background-color: rgba(30,91,142,0.4);
			padding-top: 16px;
			&:hover{
				background-color: rgba(30,91,142,1);
				.code-img{
					display: block;
				}
			}
			&:last-child{
				border-bottom-color: rgba(0,0,0,0);
			}
			.iconfont{
				color: #fff;
				font-size: 38px;
			}
			.text{
				margin-top: 12px;
				font-size: 14px;
				color: #fff;
			}
			.code-img{
				position: absolute;
				display: none;
				top: -0px;
				left: -150px;
				height: 150px;
				width: 150px;
			}
		}

	}
	.header{
		position: fixed;
		top: 0px;
		z-index: 2;
		width: 100%;
		height: 83px;
		box-sizing: border-box;
		transition: background-color 0.75s;
		.nav-box{
			width: 1200px;
			height: 82px;
			margin-left: auto;
			margin-right: auto;
			.logo{
				float: left;
				height: 82px;
				line-height: 82px;
				color: #fff;
				font-size: 20px;
				width: auto;
			}
			.nav-list{
				float: right;
				height: 82px;
				.nav-item{
					height: 82px;
					line-height: 82px;
					font-family: "microsoft yahei";
					float: left;
					color: #fff;
					font-size: 20px;
					padding-left: 10px;
					padding-right: 10px;
					user-select: none;
					cursor: pointer;
					&:hover{
						font-weight: bold;
					}
				}
				.active-nav{
					font-weight: bold;
				}
			}
		}
	}
	.active-header{
		background-color: rgba(31,91,142,0.8);		
	}
	.active-top{
		border-bottom: solid 1px #b7e8ff;
	}
	.footer{
		height: 306px;
		background-color: #333333;
		.content{
			width: 1200px;
			height: inherit;
			padding-top: 80px;
			box-sizing: border-box;
			margin-left: auto;
			margin-right: auto;
			.left-side{
				float: left;
				height: 150px;
				width: 600px;
				box-sizing: border-box;
				padding-top: 18px;
				border-right: solid 1px #fff;
				display: flex;
				align-items: center;
				justify-content: right;
				padding-right: 60px;
				.nav-line{
					overflow: hidden;
					width: 194px;
					font-size: 18px;
					color:#fff;
					margin-bottom: 40px;
					.left-text{
						float: left;
						cursor: pointer;
						user-select: none;
						&:hover{
							font-weight: bold;
						}
					}
					.right-text{
						float: right;
						cursor: pointer;
						user-select: none;
						&:hover{
							font-weight: bold;
						}						
					}
				}
			}
			.middle-side{
				float: left;
				height: 150px;
				box-sizing: border-box;
				padding-left: 60px;	
				.info{
					font-size: 14px;
					color: #fff;
					margin-bottom: 20px;
					a{
						color: #fff;
						text-decoration: none;
					}
				}
				.tel{
					margin-bottom: 34px;
				}	
			}
			.right-side{
				height: 150px;
				width: 150px;
				float: right;
			}
		}
	}
}

</style>