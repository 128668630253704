<template>
	<div class="stock-box">
		<div class="about-banner"></div>
		<div class="section-stock section">
			<div class="title-box">
				<div class="title">品牌介绍</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>	
			<div class="stock-msg-box">
				<div class="container">
					沈阳盛世汇智网络科技有限公司，网罗辽沈足球人才和热爱足球的有志之士，深度发掘足球行业痛点，
					结合足球产业的特点，创立“我i足球”APP，旨在为辽宁足球人群提供一个自己家乡的，更符合东北足球沃土的综合足球服务平台。
					“我i足球”APP 提供新闻资讯、赛事直播、智能赛事组织、智能赛程排布、组织管理、技术分析、数据分析和球员评估等应用工具，
					致力于打造出中国一流的足球服务平台。从青训到赛事、从教练到裁判、从学校到球员、从辽宁足球底蕴到足球未来薪火相传，
					“我i足球”APP将在中国足球的下一个10年中担负起承前启后的历史责任和使命。
				</div>
				<div class="app">
					<img src="../assets/app/info1.png"/>
					<img class="bottom" src="../assets/app/info2.png"/>
					<img src="../assets/app/info3.png"/>
					<img class="bottom" src="../assets/app/info4.png"/>
					<img src="../assets/app/info5.png"/>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			
		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_PC"});
		}
	},
	mounted(){
		
	}
}
	
</script>

<style lang="scss" scoped>
.stock-box{
	.about-banner{
		height: 680px;
		background-color: #1E5B8E;	
		background-image: url(../assets/new_bg.png);
		background-size: cover;
		background-position: center;
	}
	.section{
		.title-box{
			position: relative;
			height: 136px;
			box-sizing: border-box;
			border-bottom: solid 2px #ccc;
			padding-top: 72px;
			.title{
				font-size: 30px;
				color: #666666;
				text-align: center;
			}
			.line-box{
				position: absolute;
				left: 0px;
				bottom: -4px;
				right: 0px;
				margin: auto;
				z-index: 1;
				height: 6px;
				width: 198px;
				.left-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #2e8bb6;
				}
				.right-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #80ab35;				
				}
			}			
		}		
	}	
	.section-stock{
		height: 1100px;
		width: 100%;
		.stock-msg-box{
			margin-top: 82px;
			height: 1000px;
			width: 100%;
			.container{
				width: 1200px;
				height: 410px;
				margin-left: auto;
				margin-right: auto;
				padding-top: 30px;
				font-size: 24px;
				color: #666;
				line-height: 50px;
				text-indent: 50px;
			}
			.app{
				width: 1200px;
				margin: 0 auto;
				img{
					width: 15%;
					height: auto;
				}
				.bottom{
					margin-top: 20px;
					margin-left: 5%;
					margin-right: 5%;
				}
			}
		}
	}
	.product-box{
		height: 540px;
		background-image: url(../assets/1.jpg);
		background-position: center;
		background-size: cover;
		.title-box{
			border-bottom: solid 2px #fff;
			.title{
				color: #fff;
			}
			.line-box{
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;				
				}
			}			
		}
		.product-content{
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 404px;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
			.product-item{
				height: 240px;
				width: 240px;
				box-sizing: border-box;
				background-color: #fff;
				img{
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
				.main-title{
					line-height: 18px;
					font-size: 18px;
					text-align: center;
					color: #333;
				}
				.sub-title{
					margin-top: 14px;
					font-size: 14px;
					text-align: center;
					color: #666;
				}
			}
		}		
	}
	.example-box{
		width: 100%;
		.example{
			height: 570px;
			width: 100%;
			background-image: url(../assets/bg_c.png);
			.form-img{
				width: 1200px;
				height: auto;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
	}
	.step-box{
		position: relative;
		width: 100%;
		background-size: cover;
		background-image: url(../assets/stepbg.jpg);
		background-position: center;
		.img-step{
			cursor: pointer;
			width: 1203px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		
	}
	.data-box{
		.img-data{
			display: block;
			width: 1200px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}	

}


</style>