import Vue from 'vue'
import VueRouter from 'vue-router'

import Home_PC from '../page_pc/Home.vue'
import Primary_PC from '../page_pc/Primary.vue'
import Stock_PC from '../page_pc/Stock.vue'
import Account_PC from '../page_pc/Account.vue'
import Technology_PC from '../page_pc/Technology.vue'
import About_PC from '../page_pc/About.vue'
import Recruit_PC from '../page_pc/Recruit.vue'
import RecruitDetail_PC from '../page_pc/RecruitDetail.vue'
import Apply_PC from '../page_pc/Apply.vue'

import NewsList_PC from '../page_pc/NewsList.vue'
import NewsDetail_PC from '../page_pc/NewsDetail.vue'

import Agreement_PC from '../page_pc/agreement.vue'
import Privacy_PC from '../page_pc/privacy.vue'


import Home_M from '../page_m/Home.vue'//移动端index页
import Primary_M from '../page_m/Primary.vue'//移动端首页
import Apply_M from '../page_m/Apply.vue'
import NewsList_M from '../page_m/NewsList.vue'
import NewsDetail_M from '../page_m/NewsDetail.vue'

import About_M from '../page_m/About.vue'

import Course_M from '../page_m/Course.vue'

import Stock_M from '../page_m/Stock.vue'

import Account_M from '../page_m/Account.vue'

//const originalPush = VueRouter.prototype.push;
//VueRouter.prototype.push = function push(location) {
//  return originalPush.call(this, location).catch(err => err);
//};
Vue.use(VueRouter)

const routes = [
  {
	path: '',
	redirect: '/home_pc'
  },
  {
    path: '/home_m',
    name: 'Home_M',
    component: Home_M,
    redirect:'home_m/primary_m',
    children:[
	  	{
	      	name:'Primary_M',
	      	path:'primary_m',
	      	component:Primary_M,	 	      		
	  	}, 
	  	{
	      	name:'Apply_M',
	      	path:'apply_m',
	      	component:Apply_M,	 	      		
	  	},
	  	{
	      	name:'NewsList_M',
	      	path:'newslist_m',
	      	component:NewsList_M,	 	      		
	  	},
	  	{
	      	name:'NewsDetail_M',
	      	path:'newsdetail_m',
	      	component:NewsDetail_M,	 	      		
	  	},
	  	{
	      	name:'About_M',
	      	path:'about_m',
	      	component:About_M,	 	      		
	  	},
	  	{
	      	name:'Course_M',
	      	path:'course_m',
	      	component:Course_M,	 	      		
	  	},
	  	{
	      	name:'Stock_M',
	      	path:'stock_m',
	      	component:Stock_M,	 	      		
	  	},
	  	{
	  		name:'Account_M',
	      	path:'account_m',
	      	component:Account_M,	 	      		
	  	},	  	
    ]
  },
  {
    path: '/home_pc',
    name: 'Home_PC',
    component: Home_PC,
    redirect:'home_pc/primary_pc',
    children:[
	  	{
	      	name:'Primary_PC',//首页
	      	path:'primary_pc',
	      	component:Primary_PC,	 	      		
	  	},
	  	{
	      	name:'Stock_PC',//存货质押
	      	path:'stock_pc',
	      	component:Stock_PC,	 	      		
	  	},
	  	{
	      	name:'Account_PC',//应收账款
	      	path:'account_pc',
	      	component:Account_PC,	 	      		
	  	},
	  	{
	      	name:'Technology_PC',//技术支持
	      	path:'technology_pc',
	      	component:Technology_PC,	 	      		
	  	},
	  	{
	      	name:'About_PC',//关于我们
	      	path:'about_pc',
	      	component:About_PC,	 	      		
	  	},
	  	{
	      	name:'Recruit_PC',//人才招聘
	      	path:'recruit_pc',
	      	component:Recruit_PC,	 	      		
	  	},
	  	{
	      	name:'RecruitDetail_PC',//人才招聘详情
	      	path:'recruitdetail_pc',
	      	component:RecruitDetail_PC,	 	      		
	  	},	  	
	  	{
	      	name:'Apply_PC',//申请服务
	      	path:'apply_pc',
	      	component:Apply_PC,	 	      		
	  	},
	  	{
	      	name:'NewsList_PC',//新闻列表页
	      	path:'newslist_pc',
	      	component:NewsList_PC,	 	      		
	  	},
	  	{
	      	name:'NewsDetail_PC',//新闻详情页
	      	path:'newsdetail_pc',
	      	component:NewsDetail_PC,	 	      		
	  	},	
	  	{
	      	name:'Agreement_PC',//用户协议
	      	path:'agreement_pc',
	      	component:Agreement_PC,	 	      		
	  	},	
	  	{
	      	name:'Privacy_PC',//新闻详情页
	      	path:'privacy_pc',
	      	component:Privacy_PC,	 	      		
	  	},	  	
    ]
  },  
]

const router = new VueRouter({
  routes
})

export default router
