<template>
	<div class="about-box">
		<div class="banner">
			<img src="../assets/about_m.png"/>
		</div>
		<div class="about">
			<div class="title-box">
				<div class="text-box">
					公司简介
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="img-title" src="../assets/about_title_m.png"/>
			<div class="line-msg">东北首家基于智能物流监管平台的第四方供应链信息服务提供商</div>
			<div class="line-msg">主营：网络技术、计算机软硬件开发与维护、技术服务</div>
		</div>
		<div class="step">
			<div class="title-box">
				<div class="text-box">
					发展历程
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="step-img" src="../assets/step_m1.jpg"/>
		</div>
		<div class="culture">
			<div class="title-box">
				<div class="text-box">
					企业文化
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>
			<img class="our-img" src="../assets/our_m1.jpg"/>
		</div>
		<div class="address">
			<div class="title-box">
				<div class="text-box">
					联系我们
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>

			<div class="map-box">
				<template>
				  <baidu-map class="map" id="map">
				  </baidu-map>
				</template>

			</div>


		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
import BaiduMap from '../tools/baidu-map.js';
import VFooter from "./components/Footer.vue"
export default {

	components:{
		VFooter,
	},
	data(){
		return {

		}
	},
	methods:{
		createMap(){
			let map = new BMap.Map("map"); // 创建Map实例
			map.centerAndZoom(new BMap.Point(123.46710, 41.74272), 13); // 初始化地图,设置中心点坐标和地图级别
			//添加地图类型控件
			map.addControl(new BMap.MapTypeControl({
				mapTypes: [
					BMAP_NORMAL_MAP,
					BMAP_HYBRID_MAP
				]
			}));
			map.setCurrentCity("沈阳"); // 设置地图显示的城市 此项是必须设置的
			map.enableScrollWheelZoom(true);

			let marker = new BMap.Marker(new BMap.Point(123.46710, 41.74272)); // 创建标注
			map.addOverlay(marker);
			marker.addEventListener("click", function() {
				map.openInfoWindow(infoWindow, map.getCenter());
			});
			var opts = {
				width: 100, // 信息窗口宽度
				height: 50, // 信息窗口高度
			}
			var infoWindow = new BMap.InfoWindow("<p style='font-size: 12px;'>地址：辽宁省沈阳市浑南区华润置地悦玺A座3506</p>", opts); // 创建信息窗口对象
			map.openInfoWindow(infoWindow, map.getCenter());
		}
	},
	mounted(){
		this.createMap();
    this.WXConfig.wxShowMenu();
	},
}
</script>

<style lang="scss" scoped>
.about-box{
	.title-box{
		height: 54px;
		width: 100%;
		border-bottom: solid 1px #cccccc;
		.text-box{
			position: relative;
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			line-height: 54px;
			font-size: 20px;
			color: #666666;
			.left-line{
				position: absolute;
				bottom: -2px;
				left: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #2e8bb6;
			}
			.right-line{
				position: absolute;
				bottom: -2px;
				right: 0px;
				width: 50.5px;
				height: 3px;
				background-color: #80ab35;
			}
		}
	}
	.banner{
		width: 100%;
		height: 180px;
		img{
			height: inherit;
			width: inherit;
		}
	}
	.about{
		padding-top: 19px;
		padding-bottom: 3px;
		.img-title{
			display: block;
			margin-top: 42px;
			width: 340px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		.line-msg{
			width: 340px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			color: #666;
		}
	}
	.step{
		.step-img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.culture{
		padding-bottom: 22px;
		.our-img{
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 340px;
			height: auto;
			margin-top: 41px;
		}
	}
	.address{
		padding-bottom: 20px;
		.map-box{
			margin-top: 20px;
			width: 340px;
			height: 220px;
			margin-left: auto;
			margin-right: auto;
			.map{
				height: inherit;
				width: inherit;
			}
		}
	}
}
</style>
