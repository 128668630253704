<template>
	<div class="recruit-detail-box">
		<div class="recruit-banner"></div>
		<div class="abstract">
			<div class="abstract-content">
				<div class="left-side">
					<div class="title">{{recruitTitle}}</div>
					<div class="money">{{recruitMoneyScope}}</div>
				</div>
				<div class="right-side">
					<div class="tag-list">
						<div class="tag-item">{{recruitAddress}}</div>
						<div class="tag-item">{{workType}}</div>
						<div class="tag-item">{{workSeniority}}</div>
						<div class="tag-item">{{recruitQualification}}</div>
						<div class="tag-item">{{recruitNum}}人</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="recruit-content">
			<div v-html="recruitContent"></div>
		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法		
export default {
	data(){
		return {
			recruitTitle:"",
			recruitMoneyScope:"",
			recruitAddress:"",
			workType:"",
			workSeniority:"",
			recruitQualification:"",
			recruitNum:"",
			recruitContent:"",
		}
	},
	methods:{
		getRecruitDetail(recruitId){
			api.post('hyr/user/recruit/findRecruitMessageById', {
				recruitId
			}).then((res) => {
				if(res.data.status){
					this.recruitTitle=res.data.resultBody.recruitTitle;
					this.recruitMoneyScope=res.data.resultBody.recruitMoneyScope;
					this.recruitAddress=res.data.resultBody.recruitAddress;
					this.workType=res.data.resultBody.workType;
					this.workSeniority=res.data.resultBody.workSeniority;
					this.recruitQualification=res.data.resultBody.recruitQualification;
					this.recruitNum=res.data.resultBody.recruitNum;
					this.recruitContent=res.data.resultBody.recruitContent;
				}else{

				}
				
			}).catch((res) => {})			
		}
	},
	mounted(){
		let recruitId=this.$route.query.recruitId;
		this.getRecruitDetail(recruitId);
	}
}
</script>

<style lang="scss" scoped>
.recruit-detail-box{
	.recruit-banner{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/rczpbanner.jpg);
		background-position: center;
	}
	.abstract{
		height: 240px;
		background-color: #f6f6f6;
		.abstract-content{
			width: 1200px;
			height: 240px;
			margin-left: auto;
			margin-right: auto;
			.left-side{
				float: left;
				width: 600px;
				height: 240px;
				.title{
					padding-top: 70px;
					font-size: 30px;
					color: #333;
				}
				.money{
					margin-top: 20px;
					font-size: 20px;
					color: #1e5b8e;
				}
			}
			.right-side{
				float: left;
				width: 600px;
				height: 240px;	
				.tag-list{
					padding-top: 125px;
					.tag-item{
						font-size: 20px;
						color: #666;
						line-height: 24px;
						float: left;
						height: 24px;
						padding-left: 22px;
						padding-right: 22px;
						border-left: solid 1px #b3b3b3;
						&:first-child{
							border: none;
						}
					}
				}			
			}
		}
	}
	.recruit-content{
		font-size: 16px;
		width: 1200px;
		padding-top: 60px;
		padding-bottom: 60px;
		margin-left: auto;
		margin-right: auto;
		/deep/ img {
			max-width: 1200px !important;
			height: auto;
			
		}
		/deep/ ol{
			padding-left: 20px;
		}
		/deep/ ul{
			padding-left: 20px;
		}						
	}
}
</style>