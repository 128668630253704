<template>
	<!--数据筛选下拉菜单框专用组件-->
	<div class="sel-box" v-clickoutside="handleClose">
		<div class="selection" @click="handleClick($event)">
			<span class="val">{{selVal}}</span>
			<span class="iconfont icon-down"></span>
			<ul class="sel-list" v-show="selectionShow">
				<li v-for="(item,index) of options.options" class="sel-item" :key="item.val" :data-val="item.val" :data-name="item.name" >{{item.name}}</li>
			</ul>
		</div>
	</div>	
</template>

<script>
export default {
	props:{
		selInfo:{
			type:Object,
			required:true,
		},
	},	
	data(){
		return {
			selVal:"请选择所属行业",
			sendVal:-1,
			selectionShow:false,
			options:this.selInfo,
		}
	},
	methods:{
		handleClick(e){
			if(typeof(e.target.dataset.name)!=="undefined"){
				this.selVal=e.target.dataset.name;
				this.sendVal=e.target.dataset.val;
			}
			this.selectionShow=!this.selectionShow;
		},
		handleClose(){
			this.selectionShow=false;
		}
	},
    directives:{
        clickoutside:{
            bind:function(el,binding,vnode){
                function documentHandler(e){
                    if(el.contains(e.target)){
                        return false;
                    }
                    if(binding.expression){
                        binding.value(e)
                    }
                }
                el._vueClickOutside_ = documentHandler;
                document.addEventListener('click',documentHandler);
            },
            unbind:function(el,binding){
                document.removeEventListener('click',el._vueClickOutside_);
                delete el._vueClickOutside_;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.sel-box{
	background-color: #fff;
	height: 40px;	
	.selection{
		position: relative;
		cursor: pointer;
		float: left;
		box-sizing: border-box;
		padding: 0 10px;
		width: 320px;
		height: 40px;
		color: #999;
		font-size: 14px;
		border: solid 1px #D7D7D7;
		margin-left: 4px;	
		line-height: 38px;
		.iconfont{
			float: right;
			font-size: 16px;
			color: #666;
		}
		.sel-list{
			position: absolute;
			left: -1px;
			width: 320px;
			box-sizing: border-box;
			border: solid 1px #D7D7D7;
			z-index: 10;
			max-height: 176px;
			overflow-y: auto;
			&::-webkit-scrollbar {
		        width: 6px;     
		        height: 1px;
		    }
			&::-webkit-scrollbar-thumb {
		        border-radius: 6px;
		         -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
		        background: #ccc;
		    }
			&::-webkit-scrollbar-track {
		        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
		        border-radius: 6px;
		        background: #fff;
		    }			
			.sel-item{
				background-color: #fff;
				color: #999;
				font-size: 14px;
				box-sizing: border-box;
				height: 40px;
				padding: 0 10px;
				border-bottom: solid 1px #D7D7D7;
				&:last-child{
					border: none;
				}
				&:hover{
					background-color: #F2F2F2;
				}
			}
		}
	}			
}
</style>