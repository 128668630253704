<template>
	<div class="newslist-box">
		<div class="banner">
			<img src="../assets/newslist_m.png"/>
		</div>
		<div class="news-list">
			<div class="news-item" v-for="(item,index) of newsList" @click="goDetail(item.newsId)" :key="item.newsId">
				<div class="date">
					<div class="month">{{item.createTime | getMonth}}</div>
					<div class="year">{{item.createTime | getYear}}</div>
				</div>
				<div class="right-side">
					<div class="news-title">{{item.newsTitle}}</div>
					<div class="news-content">{{item.newsMessageText}}</div>
				</div>
			</div>
		</div>
		<div class="no-more" v-if="noMore">
			没有更多了
		</div>
		<div class="position" v-else>

		</div>



		<div class="loading" v-if="showLoading">
			<van-loading size="24px">加载中...</van-loading>
		</div>

	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法
export default {
	data(){
		return {
			showLoading:false,
			noMore:false,
			newsList:[],
			pageSize:10,//每页条数
			curPage:1,
			totalNum:0,
			allPage:0,
		}
	},
	filters:{
		getMonth(val){
			return val.substr(val.length-5).replace('-','/');
		},
		getYear(val){
			return val.substring(0, 4)
		}
	},
	methods:{
		getNewsList(pageNo,pageSize){
			api.post('hyr/user/news/getNewsMessageList', {
				pageNo,pageSize
			}).then((res) => {
				if(res.data.status){
					this.totalNum=res.data.resultBody.totalNum;
					this.allPage=Math.ceil(res.data.resultBody.totalNum/10);
					this.showLoading=false;
					if(this.curPage<=this.allPage){
						this.curPage++;
						this.newsList=this.newsList.concat(res.data.resultBody.message);
						this.noMore=false;
					}else{
						this.noMore=true;
					}
				}else{
					console.log("没有了")
				}

			}).catch((res) => {})
		},
      	goDetail(newsId){
      		this.$router.push({name:"NewsDetail_M",query:{newsId}});
      	},
	},
	mounted(){
    this.WXConfig.wxShowMenu();
	this.getNewsList(this.curPage,this.pageSize);
	},
    created(){
		let that=this;
	   	window.onscroll = function(){
	   		//变量scrollTop是滚动条滚动时，距离顶部的距离
	   		let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
	   		//变量windowHeight是可视区的高度
	   		let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
	   		//变量scrollHeight是滚动条的总高度
	   		let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            if(scrollTop+windowHeight==scrollHeight){
               //写后台加载数据的函数
         	    console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);

				if(that.curPage<=that.allPage){
					that.showLoading=true;
					setTimeout(()=>{
						that.getNewsList(that.curPage,that.pageSize);
					}, 1500);
				}else{
					that.noMore=true;
				}


            }   
	    }
   }
}
</script>

<style lang="scss" scoped>
.newslist-box{
	.banner{
		width: 100%;
		height: 197.5px;
		img{
			width: inherit;
			height: inherit;
		}

	}
	.news-list{
		.news-item{
			padding-left: 17.5px;
			padding-right: 17.5px;
			box-sizing: border-box;
			height: 123px;
			&:nth-child(2n){
				background-color: #f6f6f6;
			}
			.date{
				float: left;
				box-sizing: border-box;
				padding-top: 42px;
				width:52px;
				.month{
					font-size: 15px;
					color: #333;
					line-height: 15px;
				}
				.year{
					font-size: 14px;
					color: #999;
					line-height: 14px;
				}
			}
			.right-side{
				width: 288px;
				float: left;
				.news-title{
					font-size: 15px;
					font-weight: bold;
					color: #333;
					width: inherit;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-top: 28px;
				}
				.news-content{
					margin-top: 6px;
					font-size: 14px;
					color: #666;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
	.position{
		height: 60px;
		background-color: #f6f6f6;
	}
	.loading{
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		/*background-color: yellow;*/
	}
	.no-more{
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f6f6f6;
		color: #969799;
		font-size: 14px;
	}
}
</style>
