<template>
	<div class="recruit-box">
		<div class="recruit-banner"></div>
		<div class="table">
			<div class="item" v-for="(item,index) of recruitList" :key="item.recruitId" @click="goDetail(item.recruitId)">
				<div class="title">{{item.recruitTitle}}</div>
				<div class="sub-title">
					<div class="money">{{item.recruitMoneyScope}}</div>
					<div class="date">发布于{{item.createTime}}</div>
				</div>
			</div>
		</div>
		<div class="page-box">
		    <div class="pagination-box">
		        <pagination :currentPage="curPage" @getPageNumber="getCurNum" :allpage='allPage' :doubleShow="doubleShow" :inputshow="inputShow" v-show="allPage>1"></pagination>
		    </div>
		</div>
	</div>
</template>
<script>
import { api, axios } from "../tools/service.js" //封装axios方法		
import Pagination from "./components/Pagination.vue"	
export default {
	components:{
		Pagination
	},
	data (){
		return {
	        curPage: 1,//当前页数
	        allPage: 10,//默认值一共有多少页
	        inputShow:true,
	        doubleShow:true,
			pageInput:1,
			totalNum:0,//总条数
			pageSize:10,//每页条数
			recruitList:[],			
		}
	},
	watch:{
		curPage(val){
			this.getRecruitList(val,this.pageSize);
		},
		
		pageInput(val){
			if(isNaN(val)||val===0||val>this.allPage){
				console.log("请输入有效值");
			}else{
				console.log("执行结果");
			}
		}
	},	
	methods:{
      //获取当前点击的页数
      getCurNum(data) {
        this.curPage = data;
		this.$router.push({name:"Recruit_PC",query:{page:data}});        
      },
      //输入框分页传递数据
      turnPage(val){
      	if(val==="previous"){
      		if(this.pageInput>=2){
      			this.pageInput--;
      		}
      		
      	}else{
      		if(this.pageInput<this.allPage){
      			this.pageInput++;
      		}      		
      	}
      },
      getRecruitList(pageNo,pageSize){
			api.post('hyr/user/recruit/getRecruitMessageList', {
				pageNo,pageSize
			}).then((res) => {
				if(res.data.status){
					this.recruitList=res.data.resultBody.message;
					this.totalNum=res.data.resultBody.totalNum;
					this.allPage=Math.ceil(res.data.resultBody.totalNum/10);

				}else{
					this.recruitList=[];
					this.totalNum=0;
					this.allPage=0;
				}
				
			}).catch((res) => {})      	
     },
     goDetail(recruitId){
     	this.$router.push({name:"RecruitDetail_PC",query:{recruitId}});
     },
	},
	mounted(){
		let page=this.$route.query.page;
		
		this.curPage=parseInt(page)||1;
		this.getRecruitList(page,this.pageSize);
	}	
}
</script>

<style lang="scss" scoped>
.recruit-box{
	.recruit-banner{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/rczpbanner.jpg);
		background-position: center;
	}
	.table{
		height: 700px;
		width: 1200px;
		padding-top: 44px;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: flex-start;
		.item{
			cursor: pointer;
			transition: all 0.35s;
			width: 540px;
			height: 121px;
			box-sizing: border-box;
			padding-top: 36px;
			border-bottom: dashed 1px #e5e5e5;
			&:hover{
				background-color: #E9EFF4;
			}			
			.title{
				font-size: 18px;
				color: #666;
			}
			.sub-title{
				padding-top: 14px;
				.money{
					float: left;
					font-size: 14px;
					color: #1e5b8e;
				}
				.date{
					float: right;
					font-size: 14px;
					color: #666;
				}
			}
		}
	}
	.page-box{
		padding-top: 40px;
		padding-bottom: 60px;
		display: flex;
		justify-content: center;
	}	
	
}
</style>