<template>
	<div class="detail-box">
		<div class="banner">
			<img src="../assets/newslist_m.png"/>
		</div>
		<div class="title-box">
			<div class="title">{{newsTitle}}</div>
			<div class="date">{{createTime}}</div>
		</div>
		<div class="content">
			<div v-html="newsMessage"></div>
		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法
export default {
	data(){
		return {
			newsTitle:"",
			createTime:"",
			newsMessage:"",
		}
	},
	methods:{
		getNewsDetail(newsId){
			api.post('hyr/user/news/findNewsMessageById', {
				newsId
			}).then((res) => {
				if(res.data.status){
					this.newsTitle=res.data.resultBody.newsTitle;
					this.createTime=res.data.resultBody.createTime;
					this.newsMessage=res.data.resultBody.newsMessage;

				}else{

				}

			}).catch((res) => {})
		}
	},
	mounted(){
    this.WXConfig.wxShowMenu();
		let newsId=this.$route.query.newsId;
		this.getNewsDetail(newsId);
	}
}
</script>

<style lang="scss" scoped>
.detail-box{
	.banner{
		width: 100%;
		height: 197.5px;
		img{
			height: inherit;
			width: inherit;
		}
	}
	.title-box{
		box-sizing: border-box;
		padding-top: 40px;
		padding-bottom: 20px;
		border-bottom: solid 0.5px #b3b3b3;
		.title{
			width: 255px;
			margin-left: auto;
			margin-right: auto;
			font-size: 19.5px;
			color: #333;
			font-weight: bold;
			text-align: center;
		}
		.date{
			margin-top: 8px;
			text-align: center;
			font-size: 14px;
			color: #666;
		}
	}
	.content{
		font-size: 14px;
		box-sizing: border-box;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		/deep/ img {
			max-width: 340px !important;
			height: auto;
		}
		/deep/ ol{
			padding-left: 18px;
		}
		/deep/ ul{
			padding-left: 18px;
		}
	}
}
</style>
