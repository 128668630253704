<template>
	<div class="stock-box">
		<div class="about-banner"></div>
		<div class="section-stock section">
			<div class="title-box">
				<div class="title">用户协议</div>
				<div class="line-box">
					<div class="left-line"></div>
					<div class="right-line"></div>
				</div>
			</div>	
			<div class="stock-msg-box">
				<div class="container">
					
					本《用户服务协议》（以下简称”协议”）系由您与我i足球（所订立的相关权利义务规范，用以描述我i足球 向您提供服务及您使用其服务所涉及的详细规定。因此，请您于注册成为我i足球app前，切实详细地阅读本条款的所有内容。</br>
					
					一、总则</br>
					 
					1.1 用户开始使用我i足球产品或接受我i足球服务，或者注册成为我i足球 用户的，即表示同意本协议的条款。用户注册成为我i足球用户，应当按照注册页面的提示完成全部的注册程序。</br>
					1.2 注册成功后，我i足球 将给予每个用户一个用户帐号及对应密码，该用户帐号和密码由用户负责保管；用户应当对使用该账号在我i足球 所有产品（包括但不限于我i足球手机客户端、网页版等平台）上进行的所有行为和事件负法律责任。</br>
					1.3 本协议可由我i足球根据国家法律政策或公司运营要求随时更新，更新后的协议将由我i足球负责通知。如用户不同意更新的协议，应当立即停止使用我i足球产品或者服务。</br>
					 
					二、注册信息和隐私保护</br>
					 
					2.1 我i足球账号的所有权归我i足球，用户完成注册流程后，即获得我i足球帐号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。如果因注册信息不真实或更新不及时而引起的问题，并对问题发生所带来的后果，应当由用户自行承担，我i足球 不负有任何责任。</br>
					2.2 用户应当通过真实身份信息认证注册账号，且用户提交的账号名、头像等注册信息中不得出现违法和不良信息，如存在上述情况，我i足球 有权中止注册流程。同时注册后，如发现用户以虚假信息骗取账号名称注册，或其账号头像等注册信息存在违法和不良信息的，我i足球有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。</br>
					2.3 用户不应将其帐号、密码转让、出售或出借予他人使用，若用户授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。</br>
					2.4 保护用户隐私和其他个人信息是我i足球的一项基本政策，我i足球承诺不会将单个用户的注册资料及用户在使用我i足球服务时存储在我i足球的非公开内容（如有）用于任何非法的用途，且保证将单个用户的注册资料进行商业上的利用时应事先获得用户的同意，但下列情况除外：</br>
					2.4.1 事先获得用户的明确授权；</br>
					2.4.2 为维护社会公共利益；</br>
					2.4.3 用户自行在网络上公开的信息或其他已合法公开的个人信息；</br>
					2.4.4 以合法渠道获取的个人信息；</br>
					2.4.5 用户侵害我i足球合法权益，为维护前述合法权益且在必要范围内；</br>
					2.4.6 为维护我i足球的合法权益，例如查找、预防、处理欺诈或安全方面的问题；</br>
					2.4.7 根据相关政府主管部门的要求；根据相关法律法规或政策的要求；</br>
					2.4.8 其他必要情况。</br>
					2.5 我i足球可能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意我i足球 可与其分享必要的信息。并且，我i足球会要求其确保数据安全并且禁止用于任何其他用途。除此之外，我i足球不会向任何无关第三方提供或分享信息。</br>
					 
					三、使用规则</br>
					 
					3.1 用户在使用我i足球 服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶</br>
					3.1.1上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</br>
					1） 反对宪法所确定的基本原则的；</br>
					2） 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</br>
					3） 损害国家荣誉和利益的；</br>
					4） 煽动民族仇恨、民族歧视、破坏民族团结的；</br>
					5） 破坏国家宗教政策，宣扬邪教和封建迷信的；</br>
					6） 散布谣言，扰乱社会秩序，破坏社会稳定的；</br>
					7） 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</br>
					8） 侮辱或者诽谤他人，侵害他人合法权利的；</br>
					9） 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</br>
					10） 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</br>
					3.1.2不得为任何非法目的而使用网络服务系统；</br>
					3.1.3不利用我i足球服务从事以下活动：</br>
					1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</br>
					2）2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</br>
					3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</br>
					4）故意制作、传播计算机病毒等破坏性程序的；</br>
					5）其他危害计算机信息网络安全的行为。</br>
					3.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，应当赔偿我i足球 与合作公司、关联公司，并使之免受损害。对此，我i足球 有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收我i足球 帐号、追究法律责任等措施。对恶意注册我i足球帐号或利用我i足球 帐号进行违法活动，如捣乱、骚扰、欺骗其他用户，以及其他违反本协议的行为，我i足球 有权回收其帐号。同时，我i足球公司会视司法部门的要求，协助调查。</br>
					3.3 用户不得对我i足球提供的服务，进行复制、拷贝、出售、转售或用于任何其它商业目的。</br>
					3.4 用户须对自己在使用我i足球 服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在我i足球 公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予我i足球公司等额的赔偿。</br>
					 
					四、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</br>
					 
					5.1 我i足球权利</br>
					您通过我i足球上传、发布的任何作品或内容的著作权归发布者所有。我i足球一经发布，您即同意将发布内容在全球范围内的著作财产权（包括但不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利）免费地、永久性地、不可撤销地许可我i足球 。</br>
					5.2 用户专属权利</br>
					我i足球尊重他人知识产权和合法权益，呼吁用户也要同样尊重知识产权和他人合法权益。 </br>
					5.3 对于用户通过我i足球服务（包括但不限于手机客户端、网页版等）上传到我i足球 网站上可公开获取区域的任何内容，用户同意我i足球 在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</br>
					5.4 我i足球拥有本站内所有资料的版权。任何被授权的浏览、复制、打印和传播属于本站内的资料必须符合以下条件：</br>
					5.4.1 所有的资料和图像均以获得信息为目的；</br>
					5.4.2 所有的资料、图像及其任何部分都必须包括此版权声明；</br>
					5.4.3 本站所有的产品、技术与所有程序均属于我i足球知识产权，在此并未授权。”我i足球”及相关图形等为我i足球的注册商标。</br>
					5.4.4 未经我i足球许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，我i足球 将依法追究法律责任。</br>
					 
					六、青少年用户特别提示</br>
					 
					青少年用户必须遵守全国青少年网络文明公约： 要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。</br>
					 
					七、免责条款</br>
					 
					7.1 我i足球是针对注册用户的开放平台。我i足球所发布之作品、信息等内容仅代表用户观点，不代表我i足球 赞成发布信息的内容和立场，与我i足球无关。对于用户言论引发的全部责任，由用户自行承担，我i足球不承担法律责任。</br>
					 
					八、其他</br>
					 
					8.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</br>
					8.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向我i足球 所在地的人民法院提起诉讼。</br>
					8.3 我i足球未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</br>
					8.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</br>

				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			
		}
	},
	methods:{
		goApply(){
			this.$router.push({name:"Apply_PC"});
		}
	},
	mounted(){
		
	}
}
	
</script>

<style lang="scss" scoped>
.stock-box{
	.about-banner{
		height: 680px;
		background-color: #1E5B8E;	
		background-image: url(../assets/new_bg.png);
		background-size: cover;
		background-position: center;
	}
	.section{
		.title-box{
			position: relative;
			height: 136px;
			box-sizing: border-box;
			border-bottom: solid 2px #ccc;
			padding-top: 72px;
			.title{
				font-size: 30px;
				color: #666666;
				text-align: center;
			}
			.line-box{
				position: absolute;
				left: 0px;
				bottom: -4px;
				right: 0px;
				margin: auto;
				z-index: 1;
				height: 6px;
				width: 198px;
				.left-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #2e8bb6;
				}
				.right-line{
					float: left;
					width: 94px;
					height: 6px;
					background-color: #80ab35;				
				}
			}			
		}		
	}	
	.section-stock{
		height:4000px;
		width: 100%;
		.stock-msg-box{
			margin-top: 82px;
			height: 1000px;
			width: 100%;
			.container{
				width: 1200px;
				height: 410px;
				margin-left: auto;
				margin-right: auto;
				padding-top: 30px;
				font-size: 20px;
				color: #666;
				line-height: 40px;
				text-indent: 50px;
			}
		}
	}
	.product-box{
		height: 540px;
		background-image: url(../assets/1.jpg);
		background-position: center;
		background-size: cover;
		.title-box{
			border-bottom: solid 2px #fff;
			.title{
				color: #fff;
			}
			.line-box{
				.left-line{
					background-color: #fff;
				}
				.right-line{
					background-color: #fff;				
				}
			}			
		}
		.product-content{
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 404px;
			width: 1200px;
			margin-left: auto;
			margin-right: auto;
			.product-item{
				height: 240px;
				width: 240px;
				box-sizing: border-box;
				background-color: #fff;
				img{
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
				.main-title{
					line-height: 18px;
					font-size: 18px;
					text-align: center;
					color: #333;
				}
				.sub-title{
					margin-top: 14px;
					font-size: 14px;
					text-align: center;
					color: #666;
				}
			}
		}		
	}
	.example-box{
		width: 100%;
		.example{
			height: 570px;
			width: 100%;
			background-image: url(../assets/bg_c.png);
			.form-img{
				width: 1200px;
				height: auto;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
	}
	.step-box{
		position: relative;
		width: 100%;
		background-size: cover;
		background-image: url(../assets/stepbg.jpg);
		background-position: center;
		.img-step{
			cursor: pointer;
			width: 1203px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		
	}
	.data-box{
		.img-data{
			display: block;
			width: 1200px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}	

}


</style>