import axios from 'axios'
export default {
    wxShowMenu: function() {
        let url = location.href; //获取当前页面地址
        console.log(url,"url")
        axios.get('hyr/user/customApply/getJsapiTicket?url='+url).then(function(res) {
            var getMsg = res.data.resultBody;
            console.log(getMsg.appid,getMsg.timestamp,getMsg.nonceStr,getMsg.signature)
            wx.config({
                debug: false, //生产环境需要关闭debug模式

                appId: getMsg.appid, //appId通过微信服务号后台查看

                timestamp: getMsg.timestamp, //生成签名的时间戳

                nonceStr: getMsg.nonceStr, //生成签名的随机字符串

                signature: getMsg.signature, //签名

                jsApiList: [ //需要调用的JS接口列表

                    'onMenuShareTimeline', //分享给好友

                    'onMenuShareAppMessage', //分享到朋友圈

                ]

            });

            wx.ready(function() {
                //分享到朋友圈
                wx.onMenuShareTimeline({

                    title: "货易融科技", // 分享标题

                    desc: "基于智能物流监管平台的第四方供应链信息服务提供商", //分享描述
                    link:url,

                    //link: location.href.split('#')[0], // 分享链接，一定要获取当前页面的URL

                    imgUrl: 'https://aym-1300578978.cos.ap-beijing.myqcloud.com/unstructured/impawning/company/lALPD3zUO7NHiMfMyMzI_200_200.png' , // 分享图标

                });

                //分享给朋友

                wx.onMenuShareAppMessage({

                    title: "货易融科技", // 分享标题

                    desc: "基于智能物流监管平台的第四方供应链信息服务提供商", // 分享描述
                    link:url,

                    //link: location.href.split('#')[0], // 分享链接

                    imgUrl: 'https://aym-1300578978.cos.ap-beijing.myqcloud.com/unstructured/impawning/company/lALPD3zUO7NHiMfMyMzI_200_200.png' , // 分享图标
                    success: function () {
                        //alert("分享成功");
                    },
                    cancel: function () {
                        ///alert("取消分享");
                    }
                });

            });

        })

    }

}
