<template>
  <div class="container-m" id="container-m" @click="navShow=false">
  	<div class="header" :class="{'active-header':headerFlag,'active-top':isTop}">
  		<img  class="logo" src="../assets/logo_m.png" @click="goHome"/>
  		<div class="nav-box" @click.stop="navShow=!navShow">
  			<div class="pointer"></div>
  			<div class="pointer"></div>
  			<div class="pointer"></div>
  			<div class="nav-list" :class="{'active-nav':navShow}" @click="selType" >
  				<div class="nav-item" data-value = "Stock_M">存货质押</div>
  				<div class="nav-item" data-value = "Account_M">应收账款</div>
  				<div class="nav-item" data-value = "About_M">关于我们</div>
  				<div class="nav-item" data-value = "Course_M">公司荣誉</div>
  				<div class="nav-item" data-value = "NewsList_M">新闻中心</div>
  			</div>
  		</div>
  	</div>
		<div class="router-box">
			<router-view></router-view>
		</div>
  	<!-- 悬浮框-->

		<!-- <div class="suspend-nav">
			<div class="nav-item" @click="send">
				<div class="iconfont icon-qq"></div>
				<div class="text">在线客服</div>
			</div>
			<div class="nav-item">
				<div class="iconfont icon-scan"></div>
				<div class="text">二维码</div>
				<img class="code-img" src="../assets/code.jpg"/>
			</div>
			<div class="nav-item" @click="goUp">
				<div class="iconfont icon-top"></div>
				<div class="text">回到顶部</div>
			</div>
		</div> -->










  	<!--<v-footer></v-footer>-->
  </div>
</template>

<script>

import qs from 'qs'
import { api, axios } from "../tools/service.js" //封装axios方法
//import VFooter from "./components/Footer.vue"
export default {
  name: 'home',
	components:{
		//VFooter,
	},
  data(){
  	return {
			navShow:false,
			screenWidth:0,
			headerFlag:false,
			isTop:true,
  	}
  },
	watch:{
	  $route(to,from){
	    this.headerFlag=false;
	    this.goUp();
	  }
	},
  methods:{
		goUp(){
		    let nowTop = document.body.scrollTop || document.documentElement.scrollTop; // 获取当前滚动条位置
		    if (nowTop > 0) {
		        window.requestAnimationFrame(this.goUp);
		        window.scrollTo (0,nowTop - (nowTop/5));
		    }
		},
		send(){
			let qq = "529600625";//客服qq号
			window.open('http://wpa.qq.com/msgrd?v=3&uin='+qq+'&site=qq&menu=yes','_brank');
		},
		selType(e){
			let routeName=e.srcElement.dataset.value;
			if(routeName){
				this.$router.push({name:routeName});
			}
		},
		goHome(){
			this.$router.push({name:"Home_M"});
		},
    handleScroll() {
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scroll = document.documentElement.scrollTop || document.body.scrollTop;
        let scrollHeight=0;
        if(scroll==0){
        	this.isTop=true;
        }else{
        	this.isTop=false;
        }
        if(this.$route.name==="Primary_M"){
        	scrollHeight=this.screenWidth*2*0.31;
        }else if(this.$route.name==="Stock_M"||this.$route.name==="Account_M"||this.$route.name==="About_M"){
        	scrollHeight=this.screenWidth*2*0.24;
        }else{
        	scrollHeight=this.screenWidth*2*0.26;
        }
    		if(scroll>scrollHeight){
    			this.headerFlag=true;
    		}else{
    			this.headerFlag=false;
    		}


    },
  },
  mounted(){
    this.WXConfig.wxShowMenu();
  	this.screenWidth=window.screen.width;
  	window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件






  },
  destroyed(){
  	window.removeEventListener('scroll', this.handleScroll);   //  离开页面清除（移除）滚轮滚动事件
  }
}
</script>
<style lang="scss" scoped>
.container-m{
	.header{
		z-index: 1;
		position: fixed;
		top: 0px;
		transition: background-color 0.35s;
		box-sizing: border-box;
		padding-left: 17.5px;
		height: 50px;
		width: 100%;
		.logo{
			width: 115.5px;
			height: 50px;
			display: block;
			float: left;
		}
		.nav-box{
			position: relative;
			box-sizing: border-box;
			padding-right: 15px;
			padding-top: 17px;
			float: right;
			background-color: #cornflowerblue;
			height: 50px;
			width: 114px;
			.pointer{
				float: right;
				height: 8px;
				width: 8px;
				border-radius: 50%;
				background-color: #fff;
				margin: 3px;
			}
			.nav-list{
				position: absolute;
				background-color: rgba(30,91,142,0.8);
				width: 114px;
				top: 50px;
				right: 0px;
				transition: all 0.35s;
				height: 0px;
				overflow: hidden;
				.nav-item{
					height: 50px;
					width: 114px;
					border-bottom: solid 1px #518ab4;
					font-size: 15px;
					line-height: 50px;
					text-align: center;
					color: #fff;
					&:last-child{
						border: none;
					}
				}
			}
			.active-nav{
				height: 257.92px;
			}
		}
	}
	.active-header{
		background-color: rgba(30,91,142,0.8);
	}
	.active-top{
		border-bottom: solid 1px #b7e8ff;
	}
}



.suspend-nav{
	position: fixed;
	z-index: 2;
	top: 400px;
	right: 4px;
	height: 180px;
	width: 61px;
	.nav-item{
		position: relative;
		user-select: none;
		cursor: pointer;
		text-align: center;
		height: 60px;
		width: inherit;
		box-sizing: border-box;
		border-bottom: solid 1px #fff;
		background-color: rgba(30,91,142,0.4);
		padding-top: 8px;
		&:nth-child(2){
			&:hover{
				background-color: rgba(30,91,142,1);
				.code-img{
					display: block;
				}
			}
		}


		&:last-child{
			border-bottom-color: rgba(0,0,0,0);
		}
		.iconfont{
			color: #fff;
			font-size: 20px;
		}
		.text{
			margin-top: 6px;
			font-size: 12px;
			color: #fff;
		}
		.code-img{
			position: absolute;
			display: none;
			top: -0px;
			left: -75px;
			height: 75px;
			width: 75px;
		}
	}

}




</style>
