<template>
	<div class="news-list-box">
		<div class="news-list-banner"></div>
		<div class="news-list-content">
			<div class="news-list">
				<div class="news-item" v-for="(item,index) of newsList" @click="goDetail(item.newsId)" :key="item.newsId">
					<div class="left-side">
						<img :src="item.newsPicture||require('../assets/002.jpg')"/>
						<div class="date">
							<div class="day">{{item.createTime | getMonth}}</div>
							<div class="year">{{item.createTime | getYear}}</div>							
						</div>
					</div>
					<div class="right-side">
						<div class="news-title">{{item.newsTitle}}</div>
						<div class="news-msg">{{item.newsMessageText}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-box" v-if="allPage>0">
		    <div class="pagination-box">
		        <pagination :currentPage="curPage" @getPageNumber="getCurNum" :allpage='allPage' :doubleShow="doubleShow" :inputshow="inputShow" v-show="allPage>1"></pagination>
		    </div>			
		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法		
import Pagination from "./components/Pagination.vue"	
export default {
	components:{
		Pagination
	},
	data (){
		return {
	        curPage: 1,//当前页数
	        allPage: 10,//默认值一共有多少页
	        inputShow:true,
	        doubleShow:true,
			pageInput:1,
			totalNum:0,//总条数
			pageSize:10,//每页条数
			newsList:[],
		}
	},
	filters:{
		getMonth(val){
			return val.substr(val.length-5).replace('-','/');
		},
		getYear(val){
			return val.substring(0, 4)
		}
	},	
	watch:{
		curPage(val){
			this.getNewsList(val,this.pageSize);
		},
		
		pageInput(val){
			if(isNaN(val)||val===0||val>this.allPage){
				console.log("请输入有效值");
			}else{
				console.log("执行结果");
			}
		}
	},	
	methods:{
      //获取当前点击的页数
      getCurNum(data) {
        this.curPage = data;
		this.$router.push({name:"NewsList_PC",query:{page:data}});        
      },
      //输入框分页传递数据
      turnPage(val){
      	if(val==="previous"){
      		if(this.pageInput>=2){
      			this.pageInput--;
      		}
      		
      	}else{
      		if(this.pageInput<this.allPage){
      			this.pageInput++;
      		}      		
      	}
      },
      goDetail(newsId){
      	this.$router.push({name:"NewsDetail_PC",query:{newsId}});
      },
		getNewsList(pageNo,pageSize){
			api.post('hyr/user/news/getNewsMessageList', {
				pageNo,pageSize
			}).then((res) => {
				if(res.data.status){
					this.newsList=res.data.resultBody.message;
					this.totalNum=res.data.resultBody.totalNum;
					this.allPage=Math.ceil(res.data.resultBody.totalNum/10);

				}else{
					this.newsList=[];
					this.totalNum=0;
					this.allPage=0;
				}
				
			}).catch((res) => {})			
		},      
	},
	mounted(){
		let page=this.$route.query.page;
		
		this.curPage=parseInt(page)||1;
		this.getNewsList(page,this.pageSize);
	}	
}
</script>

<style lang="scss" scoped>
.news-list-box{
	.news-list-banner{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/newsbanner.jpg);
		background-position: center;
	}
	.news-list-content{
		padding-top: 38px;
		width: 1200px;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		.news-list{
			.news-item{
				cursor: pointer;
				box-sizing: border-box;
				height: 161px;
				border-bottom: dashed 1px #ccc;
				transition: all 0.35s;
				&:hover{
					background-color: #E9EFF4;
				}
				.left-side{
					height: 160px;
					float: left;
					width: 280px;
					img{
						float: left;
						display: block;
						margin-top: 41px;
						width: 120px;
						height: 78px;
					}
					.date{
						padding-top: 40px;
						padding-left: 44px;
						float: left;
						.day{
							line-height: 20px;
							font-size: 20px;
							color: #333;
						}
						.year{
							line-height: 14px;
							font-size: 14px;
							color: #999;
						}
					}
				}
				.right-side{
					height: 160px;
					float: left;
					width: 920px;
					.news-title{
						font-size: 18px;
						color: #333;
						padding-top: 36px;
						padding-bottom: 6px;
						width: inherit;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.news-msg{
						font-size: 14px;
						color: #666;
						line-height: 28px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;						
					}
				}
			}
		}
	}
	.page-box{
		padding-top: 60px;
		padding-bottom: 120px;
		display: flex;
		justify-content: center;
	}
	
}

</style>