<template>
	<div class="apply-box">
		<div class="success" v-if="successFlag">{{successPrompt}}</div>
		
		<div class="banner-box"></div>
		<div class="form-box">
			<div class="form-title">填写申请信息</div>
			<div class="input-line">
				<div class="input-title"><span class="must">*</span>申请人姓名:</div>
				<input type="text" class="input" v-model="userName" maxlength="20"/>
				<div class="prompt">{{userNamePrompt}}</div>
			</div>
			<div class="input-line">
				<div class="input-title"><span class="must">*</span>联系电话:</div>
				<input type="tel" class="input" v-model="userPhone" maxlength="11"/>
				<div class="prompt">{{userPhonePrompt}}</div>
			</div>
			<div class="input-line">
				<div class="input-title">邮箱:</div>
				<input type="text" class="input" v-model="userEmail"/>
			</div>
			<div class="input-line">
				<div class="input-title">企业/个人:</div>
				<div class="radio-box">
					<label class="label" :class="{'selected':userType==0}">
						<input class="radio" type="radio" name="userType" value='0' v-model='userType'/>
						<div class="circle">
							<div class="iconfont icon-correct"></div>
						</div>
						<div class="radio-text">企业</div>
					</label>
					<label class="label" :class="{'selected':userType==1}">
						<input class="radio" type="radio" name="userType" value='1' v-model='userType'/>
						<div class="circle">
							<div class="iconfont icon-correct"></div>
						</div>
						<div class="radio-text">个人</div>
					</label>					
				</div>
			</div>
			<div class="input-line" v-if="userType==0">
				<div class="input-title">公司名称:</div>
				<input type="text" class="input" v-model="companyName" />
			</div>
			<div class="input-line">
				<div class="input-title">融资模式:</div>
				<div class="radio-box">
					<label class="label" :class="{'selected':applyFor==0}">
						<input class="radio" type="radio" name="applyFor" value='0' v-model='applyFor'/>
						<div class="circle">
							<div class="iconfont icon-correct"></div>
						</div>
						<div class="radio-text">存货质押</div>
					</label>
					<label class="label" :class="{'selected':applyFor==1}">
						<input class="radio" type="radio" name="applyFor" value='1' v-model='applyFor'/>
						<div class="circle">
							<div class="iconfont icon-correct"></div>
						</div>
						<div class="radio-text">应收账款</div>
					</label>					
				</div>
			</div>			

			


			<div class="input-line" v-if="applyFor==0">
				<div class="input-title"><span class="must">*</span>质押品类:</div>
				<input type="text" class="input" v-model="pledgeGood" key='pledgeGood'/>
				<div class="prompt">{{pledgeGoodPrompt}}</div>
			</div>
			
			<div class="input-line" v-else>
				<div class="input-title"><span class="must">*</span>所属行业:</div>
				<v-selection :sel-info="type" class="selection" ref="type"></v-selection>
				<div class="prompt">{{serviceTypePrompt}}</div>
			</div>
			
			
			

			<div class="input-line">
				<div class="input-title">融资需求（万元）:</div>
				<input type="text" class="input" v-model="needMoney" maxlength="10"/>
				<div class="prompt">{{needMoneyPrompt}}</div>
			</div>

			<div class="mark-line">
				<div class="input-title">备注:</div>
				<textarea class="textarea" maxlength="50" v-model="userRemark"></textarea>
			</div>
			<div class="submit" @click="submit">提交申请</div>
			
			
			
		</div>
	</div>
</template>

<script>
import { api, axios } from "../tools/service.js" //封装axios方法		
import VSelection from "./components/VSelection.vue"	
export default {
	components:{
		VSelection
	},
	data (){
		return {
			successFlag:false,
			successPrompt:'',
			submitFlag:true,
			userName:'',
			userPhone:'',
			userEmail:'',
			userType:0,
			companyName:'',
			applyFor:0,
			pledgeGood:"",
			serviceType:"",
			needMoney:"",
			userRemark:"",
			pledgeGood:"",
			
			userNamePrompt:'',
			userPhonePrompt:'',
			pledgeGoodPrompt:'',
			serviceTypePrompt:'',
			needMoneyPrompt:"",
			
			
			
			
			type: {
				//部门下拉菜单数据
				title: "所属行业",
				options: [
					{
						"name": "农、林、牧、渔业",
						"val": 1,
					},
					{
						"name": "采矿业",
						"val": 2,
					},
					{
						"name": "制造业",
						"val": 3,
					},
					{
						"name": "电力、热力、燃气及水的生产和供应业",
						"val": 4,
					},
					{
						"name": "环境和公共设施管理业",
						"val": 5,
					},
					{
						"name": "建筑业",
						"val": 6,
					},
					{
						"name": "交通运输、仓储业和邮政业",
						"val": 7,
					},
					{
						"name": "信息传输、计算机服务和软件业",
						"val": 8,
					},
					{
						"name": "批发和零售业",
						"val": 9,
					},
					{
						"name": "住宿、餐饮业",
						"val": 10,
					},
					{
						"name": "金融、保险业",
						"val": 11,
					},
					{
						"name": "房地产业",
						"val": 12,
					},
					{
						"name": "租赁和商务服务业",
						"val": 13,
					},
					{
						"name": "科学研究、技术服务和地质勘查业",
						"val": 14,
					},
					{
						"name": "水利、环境和公共设施管理业",
						"val": 15,
					},
					{
						"name": "居民服务和其他服务业",
						"val": 16,
					},
					{
						"name": "教育",
						"val": 17,
					},					
					{
						"name": "卫生、社会保障和社会服务业",
						"val": 18,
					},
					{
						"name": "文化、体育、娱乐业",
						"val": 19,
					},	
					{
						"name": "综合（含投资类、主业不明显）",
						"val": 20,
					},					
					{
						"name": "其他",
						"val": 21,
					},					
				]
			},			
		}
	},
	watch:{
		userType(){
			this.companyName="";
		},
		applyFor(){
			this.pledgeGood="";
		},
	},
	methods:{
		submit(){
			if(this.submitFlag){
				if(this.userName.length===0){
					this.userNamePrompt="! 申请人姓名不可为空";
					return;
				}else{
					this.userNamePrompt="";
				}
				if(this.userPhone.length===0){
					this.userPhonePrompt="! 联系电话不可为空";
					return;
				}else{
					this.userPhonePrompt="";
				}				
				
			    let isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;//手机号码
			    let isMob= /^0?1[3|4|5|8][0-9]\d{8}$/;// 座机格式  区号之后用'-'隔开
			    if(isMob.test(this.userPhone)||isPhone.test(this.userPhone)){

			    }
			    else{
			    	this.userPhonePrompt="! 联系电话格式不正确";
			        return;
			    }				
				
				
				
				if(this.applyFor==0){
					if(this.pledgeGood.length===0){
						this.pledgeGoodPrompt="! 质押品类不可为空";
						return;
					}else{
						this.pledgeGoodPrompt="";
					}
				}else{
					if(this.$refs.type.sendVal===-1){
						this.serviceTypePrompt="! 请选择所属行业";
						return
					}else{
						this.serviceTypePrompt="";
					}
				}
				
				if(this.needMoney!==""){
					let reg = /^[1-9]\d*$/;
					if(reg.test(this.needMoney)){
						this.needMoneyPrompt="";
					}else{
						this.needMoneyPrompt="! 请输入正整数";
						return;
					}					
				}else{
					this.needMoneyPrompt="";
				}
				
				

				



				let sendData={};
				
				let userName=this.userName;
				let userPhone=this.userPhone;
				let userEmail=this.userEmail;
				let userType=this.userType;
				let applyFor=this.applyFor;
				let needMoney=this.needMoney;
				let userRemark=this.userRemark;
				sendData={userName,userPhone,userEmail,userType,applyFor,needMoney,userRemark};
				
				if(userType==0){
					sendData.companyName=this.companyName;
				}else{
					
				}
				if(applyFor==0){
					sendData.pledgeGood=this.pledgeGood;
				}else{
					sendData.serviceType=this.$refs.type.sendVal;
				}
				
				api.post('hyr/user/customApply/saveCustomApply', sendData).then((res) => {
					this.submitFlag=true;
					this.successFlag=true;
					if(res.data.status){
						this.successPrompt="提交成功";
						setTimeout(()=>{
							this.successFlag=false;
							this.$router.push({name:"Home_PC"}); 
						},750)
					}else{
						this.successPrompt="提交失败";
						setTimeout(()=>{
							this.successFlag=false;
						},750)						
						
					}
					
					
				}).catch((res) => {this.submitFlag=true;})				
				
				
				
				
				
				
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.apply-box{
	.success{
		font-size: 16px;
		color: #fff;
		width: 145px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		border-radius: 5px;
		background-color: rgba(0,0,0,0.75);
		position: fixed;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		margin: auto;
		z-index: 1;
	}
	.banner-box{
		height: 480px;
		background-color: #1E5B8E;	
		background-image: url(../assets/fwsqbanner.jpg);
		background-position: center;		
	}
	.form-box{
		width: 1200px;
		height: 1040px;
		box-sizing: border-box;
		padding-top: 76px;
		margin-left: auto;
		margin-right: auto;
		background-color:#fff;
		.form-title{
			font-size: 18px;
			color: #333;
			margin-bottom: 56px;
		}
		.mark-line{
			.input-title{
				width: 134px;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				float: left;
				font-size: 14px;
				color: #666;
				text-align: right;
				padding-right: 18px;
				.must{
					color: #DD5044;
				}
			}
			.textarea{
				box-sizing: border-box;
				outline: none;
				padding: 10px;
				height: 120px;
				width: 320px;
				border: solid 1px #ccc;
				resize: none;
				&:focus{
					border-color: #1E5B8E;
				}
			}			
		}
		.input-line{
			height: 40px;
			margin-bottom: 20px;
			.input-title{
				width: 134px;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				float: left;
				font-size: 14px;
				color: #666;
				text-align: right;
				padding-right: 18px;
				.must{
					color: #DD5044;
				}
			}
			.selection{
				float: left;
			}
			.input{
				box-sizing: border-box;
				outline: none;
				padding-left: 10px;
				padding-right: 10px;
				height: 40px;
				width: 320px;
				border: solid 1px #ccc;
				&:focus{
					border-color: #2E8BB6;
				}
			}
			.prompt{
				padding-left: 10px;
				display: inline-block;
				line-height: 40px;
				font-size: 12px;
				color: #D9001B;
			}			
			.radio-box{
				height: 40px;
				width: 600px;
				padding-left: 4px;
				float: left;
				.label{
					cursor: pointer;
					float: left;
					position: relative;
					display: inline-block;
					height: 40px;
					line-height: 40px;
					width: 140px;
					overflow: hidden;
					.radio{
						position: absolute;
						left: 0px;
						top: -20px;
					}
					.circle{
						float: left;
						transition: all 0.35s;
						user-select: none;
						margin-top: 9px;
						width: 22px;
						height: 22px;
						border-radius: 50%;
						border: solid 1px #ddd;
						line-height: 22px;
						text-align: center;
						.iconfont{
							font-weight: bold;
							font-size: 20px;
							color: #fff;
						}
					}
					.radio-text{
						user-select: none;
						float: left;
						padding-left: 10px;
						font-size: 14px;
						color: #999;
					}
				}
				.selected{
					.circle{
						border-color: #1e5b8e;
						background-color: #1e5b8e;						
					}
					.radio-text{
						color: #1e5b8e;
					}
				}
			}
		}
		.submit{
			margin-top: 80px;
			margin-left: auto;
			margin-right: auto;
			width: 240px;
			height: 60px;
			text-align: center;
			line-height: 60px;
			background-color: #1e5b8e;
			user-select: none;
			cursor: pointer;
			font-size: 26px;
			/*font-weight: bold;*/
			color: #fff;
			transition: all 0.35s;
			
			&:hover{
				opacity: 0.8;
				
			}
		}
	}
}
</style>