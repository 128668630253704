import axios from 'axios'    //引入axios
import qs from 'qs' 
import Vue from 'vue'
import router from '../router'
let baseURL=process.env.BASE_URL.toString();//获取配置好的基础路径，直接使用process.env.API_BASE并不是字符串，所以这里要转换
axios.defaults.baseURL = baseURL;//baseURL用于自动切换本地环境接口和生产环境接口
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.withCredentials = true;
//封装axios,用于异步操作的同步写法，与async结合使用
let v = new Vue();
const api={
    get(url,data){
    	//get接口封装
        return new Promise((resolve,reject)=>{
            axios.get(url,{params:data}).then((res)=>{
            	resolve(res)
            }).catch((err)=>{
                reject(err);
            })
        })
    },
    post(url,data){
    	//post接口封装
        return new Promise((resolve,reject)=>{
            axios.post(url,qs.stringify(data)).then((res)=>{
	            resolve(res)
            }).catch((err)=>{
                reject(err);
            })
        })      
    },
    
}

export {api,axios}